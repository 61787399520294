import { StyleSheet, View } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
  },
  rowMiddle: {
    borderBottom: "1pt solid #17aee5",
    paddingBottom: 6,
    paddingTop: 6,
  },
  rowFirst: {
    borderBottom: "1pt solid #17aee5",
    paddingBottom: 2,
    paddingTop: 8,
    fontFamily: "NexaSlabHeavy",
  },
  rowLast: {
    paddingBottom: 8,
    paddingTop: 8,
    fontFamily: "NexaSlabHeavy",
  },
} as const);

type RowPlaceStyle =
  | typeof styles.rowFirst
  | typeof styles.rowLast
  | typeof styles.rowMiddle;

type PdfTableRowProps = {
  first?: boolean;
  last?: boolean;
};

export const PdfTableRow = ({
  first,
  last,
  children,
}: PropsWithChildren<PdfTableRowProps>) => {
  let rowPlaceStyle: RowPlaceStyle = styles.rowMiddle;
  if (first) {
    rowPlaceStyle = styles.rowFirst;
  } else if (last) {
    rowPlaceStyle = styles.rowLast;
  }

  return <View style={[styles.row, rowPlaceStyle]}>{children}</View>;
};
