import { useQuery } from "react-query";
import client from "../../api/client";
import { IPensionOverview } from "../../types/PensionOverview";

const getPensionOverview = async () => {
  const result = await client.get("Portal/OtherPensionOverview");
  return result.data.data;
};

export const usePensionOverviewQuery = (haveAccount: boolean) => {
  const { isLoading, error, data, refetch } = useQuery<IPensionOverview, Error>(
    "pensionOverview",
    getPensionOverview,
    {
      enabled: haveAccount,
    }
  );

  return { isLoading, error, data, refetch };
};
