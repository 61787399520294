import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "../../api/client";
import { ParticipantSummary } from "../../types/ParticipantSummary";

const putParticipant = async (data: ParticipantSummary) => {
  await client.put("Portal/PortalParticipant", {
    id: data.id,
    firstName: data.firstName,
    emailContact: data.emailContact,
    employmentType: data.employmentType,
    phone2: data.phone2,
    sector: data.sector,
    city: data.city,
    country: data.country,
    houseNumber: data.houseNumber,
    houseNumberAddition: data.houseNumberAddition,
    postalCode: data.postalCode,
    street: data.street,
  });
};

const getParticipantSummary = async () => {
  const result = await client.get("Portal/ParticipantSummary");
  return result.data.data;
};

export const useParticipantSummaryQuery = () => {
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { isLoading, error, data, refetch } = useQuery<
    ParticipantSummary,
    Error
  >("ParticipantSummary", getParticipantSummary);

  const updateParticipant = useMutation(
    (summary: ParticipantSummary) => {
      setIsCreating(true);
      return putParticipant(summary);
    },
    {
      onError: (e: Error) => console.error(e.message),
      onSuccess: () => queryClient.invalidateQueries("participantSummary"),
      onSettled: () => setIsCreating(false),
    }
  );

  return { isLoading, error, data, refetch, isCreating, updateParticipant };
};
