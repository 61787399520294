import React, { FunctionComponent } from "react";

const SuitCaseLocked: FunctionComponent<{
  color: string;
  backgroundColor?: string;
}> = ({ color, backgroundColor }) => {
  const colorBackgorund = `#${backgroundColor ?? `fff`}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g transform="translate(1.5 1.5)">
        <circle
          cx="38.5"
          cy="38.5"
          r="38.5"
          fill={colorBackgorund}
          stroke={`#${color}`}
          strokeWidth="3"
        />
        <path
          d="M30.77,31.77c.1,0,.75.4.52.5a.66.66,0,0,0,0-.14c0-.25,0-.51,0-.76V25.72c.12.16-.32.36-.39.45s-.19,0,.05,0a4.859,4.859,0,0,0,.54,0h9.09a3.89,3.89,0,0,0,1.13,0h0c0,.13-.77-.4-.52-.5a3.379,3.379,0,0,0,0,.45V31.7a1.74,1.74,0,0,0,0,.51c-.09-.11.38-.57.48-.48s-.32,0-.38,0H30.77a1.5,1.5,0,1,0,0,3h8.87c1.49,0,3.37.29,4.26-1.23a4.15,4.15,0,0,0,.34-2.17v-2.7c0-1.62.44-4.07-1.16-5.07-1-.65-2.71-.39-3.87-.39H31.43a3.78,3.78,0,0,0-1.76.26,2.84,2.84,0,0,0-1.38,2.84v5.61a2.62,2.62,0,0,0,2.48,2.85,1.5,1.5,0,1,0,0-3Z"
          fill="#010102"
        />
        <path
          d="M54.76,58.7a.42.42,0,0,1-.41.43H18.18a.42.42,0,0,1-.41-.43V31.15a.42.42,0,0,1,.41-.43H54.35a.42.42,0,0,1,.41.43Z"
          fill="#17aee5"
        />
        <path
          d="M53.31,58.3l-.12.31.69-.9c.54-.23.57,0,.22-.08a2.269,2.269,0,0,0-.74,0H18.46c-.3,0-.39-.15.15.08l.69.9s-.11.11-.07-.09a3.25,3.25,0,0,0,0-.54V31.41c0-.22.09-.23.07-.17l-.69.9c-.55.23-.57,0-.23.08a2.34,2.34,0,0,0,.75,0h34.9c.3,0,.39.15-.15-.08l-.69-.9s.1-.11.07.1a3.13,3.13,0,0,0,0,.53V58.7a1.5,1.5,0,1,0,3,0V46.17c0-4.9.15-9.83,0-14.73-.06-1.82-1.36-2.22-2.85-2.22H21.54a31.2,31.2,0,0,0-3.35,0c-2.28.25-1.92,2.51-1.92,4.16V57.6c0,1.51.28,3,2.18,3H40.94c4.36,0,8.74.13,13.09,0a2.17,2.17,0,0,0,2.18-1.53C56.92,57.31,54,56.53,53.31,58.3Z"
          fill="#010102"
        />
        <path
          d="M55.49,43a.43.43,0,0,1-.43.43H17.47A.43.43,0,0,1,17,43V29.85a.43.43,0,0,1,.43-.43H55.06a.43.43,0,0,1,.43.43Z"
          fill="#17aee5"
        />
        <path
          d="M54,42.59c.07-.26,1.27-.47.91-.67H18.18a2.58,2.58,0,0,0-.68,0c-.44.14.86,1.52,1,1a2.89,2.89,0,0,0,0-.72V35.92a40.16,40.16,0,0,0,0-5.7c-.07-.46.28-.65-.53.16-1.3,1.3-.66.42-.4.54a3.36,3.36,0,0,0,.95,0H53.73a4.79,4.79,0,0,0,1.07,0h.23c-.3.27-.76-1.53-1-.92A6.07,6.07,0,0,0,54,32V43a1.5,1.5,0,0,0,3,0V31.25c0-1.52,0-3.21-2-3.33-.91-.06-1.84,0-2.74,0H19.4a11.33,11.33,0,0,0-2.33,0c-2,.44-1.53,3.06-1.53,4.57v7.55c0,1.48-.49,4.09,1.31,4.72a7.84,7.84,0,0,0,2.37.11H46.37c2.84,0,5.73.14,8.57,0a2,2,0,0,0,2-1.53A1.517,1.517,0,0,0,54,42.59Z"
          fill="#010102"
        />
        <rect
          width="4.81"
          height="2.27"
          transform="translate(33.86 42.93)"
          fill="#17aee5"
        />
        <path
          d="M38.67,43.71H33.86l1.5,1.5V42.93l-1.5,1.5h4.81l-1.5-1.5v2.28a1.5,1.5,0,0,0,3,0V42.93a1.52,1.52,0,0,0-1.5-1.5H33.86a1.52,1.52,0,0,0-1.5,1.5v2.28a1.52,1.52,0,0,0,1.5,1.5h4.81a1.5,1.5,0,1,0,0-3Z"
          fill="#010102"
        />
        <path d="M44.75,29.45V24.09a7.74,7.74,0,0,1,2.72-6.4,7.29,7.29,0,0,1,8.25-.56,7.39,7.39,0,0,1,3.53,6.44c0,2,0,3.92,0,5.88a1.5,1.5,0,1,0,3,0V23.64A10.5,10.5,0,0,0,58,15.05a10.25,10.25,0,0,0-16.25,8.31v6.09a1.5,1.5,0,1,0,3,0Z" />
        <rect
          width="25.13"
          height="20.94"
          rx="2.22"
          transform="translate(39.44 29.39)"
          fill="#e73180"
        />
        <path d="M62.34,48.83H42.11c-.7,0-1.1-.17-1.17-.95-.26-2.92,0-6,0-8.89V33.87a11.28,11.28,0,0,1,0-2.25c.18-.91,1-.73,1.76-.73H61.36c.71,0,1.52-.16,1.7.73a15.93,15.93,0,0,1,0,3V46.2c0,.71.35,2.56-.72,2.63-1.91.14-1.93,3.14,0,3a3.84,3.84,0,0,0,3.72-4V32.74c0-1.55-.13-3-1.5-4.06a5.21,5.21,0,0,0-3.38-.75H50.52c-2.9,0-5.81-.08-8.7,0a3.83,3.83,0,0,0-3.88,3.74q0,1.5,0,3V46.12a10.26,10.26,0,0,0,.2,3.18,3.91,3.91,0,0,0,4,2.53c6.54.05,13.08,0,19.61,0h.58a1.5,1.5,0,1,0,.01-3Z" />
        <circle
          cx="2.82"
          cy="2.82"
          r="2.82"
          transform="translate(49.18 35.24)"
          fill="#e73180"
        />
        <path d="M53.32,38.06a1.851,1.851,0,0,1,0,.23v-.17a1,1,0,0,1-.09.37.74.74,0,0,1-.07.21s.17-.36.06-.15l-.23.38c-.15.23.21-.23.08-.1A1.592,1.592,0,0,0,53,39c-.1.11-.43.3,0,.06l-.3.2-.14.07c-.23.13.38-.12,0,0a2.42,2.42,0,0,1-.36.1h-.45c-.2,0,.41.07.13,0l-.22-.05-.22-.07c-.12,0-.12-.12.15.06l-.32-.17L51.1,39c-.16-.09.29.27.1.08s-.2-.23-.31-.32c.24.2.17.24.1.13l-.12-.19-.07-.12c-.07-.13-.08-.31,0,.08a1.573,1.573,0,0,0-.11-.35v-.15c-.07-.28,0,.4,0,0v-.35c0-.12.09-.34,0,.09v-.15a1.155,1.155,0,0,1,.11-.36c.08-.28-.18.33,0,.08l.11-.19c0-.06.07-.11.1-.16s.18-.12-.08.1c.09-.08.17-.19.25-.27s.24-.19,0,0h0s.27-.21.38-.21-.38.13-.15.06l.22-.07c.07,0,.46-.09.17-.05h.4c.28,0-.36-.07-.1,0l.44.12c.26.09-.28-.15-.12-.05a2.53,2.53,0,0,0,.23.12l.12.08c.32.22-.19-.18,0,0l.26.26c.2.2-.2-.31-.05-.07l.12.19.11.19c.12.23-.12-.35,0-.08a1.531,1.531,0,0,1,.13.43c.04.15,0,.2,0-.17v.32a1.54,1.54,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.5-1.5A4.39,4.39,0,0,0,53.41,34a4.329,4.329,0,1,0,2.91,4.09,1.5,1.5,0,0,0-1.5-1.5A1.52,1.52,0,0,0,53.32,38.06Z" />
        <path d="M50.5,41.9v3a1.54,1.54,0,0,0,.44,1.1,1.5,1.5,0,0,0,2.56-1.06v-3a1.54,1.54,0,0,0-.44-1.06A1.47,1.47,0,0,0,52,40.4a1.52,1.52,0,0,0-1.5,1.5Z" />
      </g>
    </svg>
  );
};

export default SuitCaseLocked;
