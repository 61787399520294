import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
  useState,
} from "react";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import { Props } from "../../shared/intrefaces";
import { IJumbotronContext } from "./IJumbotronContext";
import { defaultJumbotronProps, JumbotronProps } from "./JumbotronProps";

export const JumbotronContext = createContext<IJumbotronContext | undefined>(
  undefined
);

export const useJumbotronContext = () => {
  const context = useContext(JumbotronContext);

  if (!context) {
    throw new Error(
      "useJumbotronContext must be used within a JumbotronContext"
    );
  }

  return context;
};

export const JumbotronProvider: FunctionComponent<Props> = ({ children }) => {
  const [language, setLanguage] = useState<string>(
    localStorage.getItem("langData") ?? "nl"
  );
  useGoogleTagManager();
  const [jumbotronProps, setJumbotronProps] = useState<JumbotronProps>(
    defaultJumbotronProps
  );

  const value = useMemo(
    () => ({
      language,
      setLanguage,
      jumbotronProps,
      setJumbotronProps,
    }),
    [language, setLanguage, jumbotronProps, setJumbotronProps]
  );

  return (
    <JumbotronContext.Provider value={value}>
      {children}
    </JumbotronContext.Provider>
  );
};
