import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./AppWraper";
import * as serviceWorker from "./serviceWorker";
import { ConfProvider } from "./features/configuration/ConfigurationProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import "./scss/index.scss";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
declare module "react" {
  interface HTMLAttributes<T> extends DOMAttributes<T> {
    anim?: number;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfProvider>
        <I18nextProvider i18n={i18n}>
          <AppWrapper />
        </I18nextProvider>
      </ConfProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
