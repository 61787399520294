import React, { FunctionComponent } from "react";

const PigUnlocked: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g transform="translate(1.5 1.5)">
        <circle
          cx="38.5"
          cy="38.5"
          r="38.5"
          fill="#fff"
          stroke={`#${color}`}
          strokeWidth="3"
        />
        <path
          d="M22.38,34.16l4.09,4.09c1.67-.16,5.22-2.81,6.5-4.09L22.38,23.57a7.5,7.5,0,0,0,0,10.59Z"
          fill="#17aee5"
        />
        <path d="M21.32,35.22c1.06,1.06,2.11,2.12,3.17,3.17.29.29.57.6.87.87a1.91,1.91,0,0,0,2,.34A12.49,12.49,0,0,0,31,37.7a22.29,22.29,0,0,0,3-2.48,1.52,1.52,0,0,0,0-2.12l-9.27-9.27-1.32-1.32a1.53,1.53,0,0,0-2.12,0,9.1,9.1,0,0,0,0,12.71c1.35,1.39,3.47-.73,2.12-2.12a6.06,6.06,0,0,1,0-8.47H21.32l9.27,9.27,1.32,1.32V33.1a19,19,0,0,1-2.72,2.18,18.29,18.29,0,0,1-1.7,1l-.39.19c-.06,0-.34.14-.15.07l-.4.14c-.09,0-.48.09-.08,0l1.06.44L23.44,33.1a1.5,1.5,0,0,0-2.12,2.12Z" />
        <path
          d="M57.12,42A14.46,14.46,0,0,0,42.79,29.33H32.42a14.45,14.45,0,0,0-13.76,10H12.44v8.83h6.23a14.42,14.42,0,0,0,5.83,7.66V62.4H31V58.14a13.592,13.592,0,0,0,1.44.08H42.79a14.27,14.27,0,0,0,4.1-.6V62.4h6.48V53.61a14.44,14.44,0,0,0,3.86-9.84h0Z"
          fill="#17aee5"
        />
        <path d="M58.56,41.57a16.07,16.07,0,0,0-6.91-11,17.12,17.12,0,0,0-9.85-2.69H34.09A19.66,19.66,0,0,0,26,29.18,16.16,16.16,0,0,0,17.22,39l1.44-1.1H12.44a1.52,1.52,0,0,0-1.5,1.5v8.83a1.52,1.52,0,0,0,1.5,1.5h6.23l-1.45-1.1a16.6,16.6,0,0,0,6.52,8.56L23,55.85V62.4a1.52,1.52,0,0,0,1.5,1.5H31a1.52,1.52,0,0,0,1.5-1.5V58.14L31,59.64c2.48.22,5,.08,7.48.08,3,0,5.93.16,8.83-.65l-1.9-1.45V62.4a1.52,1.52,0,0,0,1.5,1.5h6.48a1.52,1.52,0,0,0,1.5-1.5V53.61l-.44,1.06A16.69,16.69,0,0,0,58.62,42c-.12-1.92-3.12-1.93-3,0A15.09,15.09,0,0,1,55,48.19a12.52,12.52,0,0,1-2.27,3.92A2.71,2.71,0,0,0,51.87,54c-.06,2.78,0,5.58,0,8.37l1.5-1.5H46.89l1.5,1.5V57.62a1.51,1.51,0,0,0-1.9-1.44c-2.68.75-5.51.54-8.27.54-2.4,0-4.85.13-7.24-.08a1.51,1.51,0,0,0-1.5,1.5V62.4L31,60.9H24.5L26,62.4V56.32a2.49,2.49,0,0,0-.13-1c-.37-.88-1.68-1.46-2.37-2.12a13.26,13.26,0,0,1-3.39-5.36,1.54,1.54,0,0,0-1.44-1.1H12.44l1.5,1.5V39.36l-1.5,1.5h6.22a1.54,1.54,0,0,0,1.45-1.1,13.17,13.17,0,0,1,6.64-7.63,15.24,15.24,0,0,1,7-1.3h7A17.35,17.35,0,0,1,48,31.93a13.25,13.25,0,0,1,7.63,10.44C55.94,44.27,58.83,43.46,58.56,41.57Z" />
        <path d="M28.15,37.56v-.22a1.14,1.14,0,0,0-.12-.58,1.21,1.21,0,0,0-.31-.48,1.11,1.11,0,0,0-.49-.31,1.06,1.06,0,0,0-.58-.13h-.39a1.47,1.47,0,0,0-.67.39l-.23.3a1.55,1.55,0,0,0-.21.76v.22a1.27,1.27,0,0,0,.13.58,1.32,1.32,0,0,0,.8.8,1.13,1.13,0,0,0,.57.12l.4-.05a1.54,1.54,0,0,0,.67-.38l.23-.31a1.42,1.42,0,0,0,.2-.76Z" />
        <path d="M41.72,29.55l-2.46-2.77a17.43,17.43,0,0,1-2.56-3.26,7.26,7.26,0,0,1,12-8c1.3,1.43,3.42-.69,2.12-2.12a10.25,10.25,0,0,0-17.66,8.88,11.78,11.78,0,0,0,3.08,5.57l3.39,3.84c1.28,1.44,3.4-.68,2.12-2.13Z" />
        <rect
          width="25.13"
          height="20.94"
          rx="2.22"
          transform="translate(35.838 32.029) rotate(-15.75)"
          fill="#e73180"
        />
        <path d="M42.37,49.65l-2-7.1c-.8-2.82-1.86-5.68-2.42-8.56-.14-.72.14-1,.79-1.22,1-.31,2-.58,3.07-.87L52.18,29l4.33-1.22a9.44,9.44,0,0,1,1.78-.5c1-.08,1,1,1.24,1.73l1.25,4.44,2.67,9.45c.19.66.48,1.31-.35,1.66a18.9,18.9,0,0,1-2.31.65l-11.2,3.16-4.83,1.36c-.65.18-2,.87-2.39-.06-.66-1.8-3.56-1-2.9.79A3.85,3.85,0,0,0,44.29,53c1.11-.29,2.2-.62,3.29-.93L58.84,48.9l4.23-1.19a4.58,4.58,0,0,0,3.2-2.14c.7-1.33.31-2.62-.07-4l-1.41-5c-.89-3.14-1.71-6.3-2.66-9.41a3.82,3.82,0,0,0-4.65-2.78c-.8.18-1.59.45-2.38.67L44,28.16,39.28,29.5a11.7,11.7,0,0,0-2.51.82c-3.35,1.85-1.19,6.29-.43,9l2.94,10.41.19.69a1.5,1.5,0,1,0,2.9-.77Z" />
        <circle
          cx="2.82"
          cy="2.82"
          r="2.82"
          transform="translate(47.46 34.14)"
          fill="#e73180"
        />
        <path d="M46.12,38.12A4.4,4.4,0,0,0,50,41.27a4.29,4.29,0,0,0,4.13-2.44,4.58,4.58,0,0,0,.34-2.78,4.17,4.17,0,0,0-1.23-2.21,4.32,4.32,0,0,0-7.16,4.28,1.5,1.5,0,1,0,2.89-.8V37.1c-.06-.24,0,.17,0,.17a1.12,1.12,0,0,1,0-.41,1.09,1.09,0,0,0,0-.18c0,.37,0,.29,0,.14a2.36,2.36,0,0,1,.1-.38c0-.16.17-.23,0,.09l.06-.13a3.871,3.871,0,0,1,.21-.34c.1-.16-.26.3-.08.1a1.488,1.488,0,0,1,.13-.13l.13-.13.11-.09L49.4,36c0-.09.23-.15.31-.2s.31-.08-.08,0l.18-.06.37-.1h.13a1.1,1.1,0,0,1,.39.09l.17.06c.26.08-.28-.16-.12-.06l.34.2c.13.09.19.22-.07-.06l.14.12a3.071,3.071,0,0,1,.25.26c.12.15-.23-.33-.08-.09l.1.15.08.17c.14.24-.11-.33,0-.08a2.77,2.77,0,0,1,.12.41c0,.14,0,.26,0-.14a1.231,1.231,0,0,0,0,.19c0,.12,0,.24,0,.37s.08-.42,0-.15v.22l-.06.21c0,.05-.11.28,0,0h0l-.09.16-.1.15c-.15.23.21-.24.08-.1l-.31.31c-.12.1-.19.07.1-.07a1.3,1.3,0,0,0-.15.1l-.16.09h0l-.19.06h-.18c-.28.07.4,0,.06,0h-.41c-.29,0,.39.08.11,0h-.22l-.21-.06c-.13,0-.25-.18.09,0-.12-.08-.25-.13-.37-.21s-.25-.18,0,0,0,0-.06-.07l-.11-.11-.09-.1c-.1-.12-.07-.08.1.13-.07-.13-.16-.26-.24-.39s0-.21.06.15a1.252,1.252,0,0,0-.1-.29,1.5,1.5,0,0,0-2.89.8Z" />
        <line
          x2="0.82"
          y2="2.93"
          transform="translate(51.33 40.65)"
          fill="#e73180"
        />
        <path d="M49.88,41.05l.72,2.57.11.36a1.52,1.52,0,0,0,.69.9,1.5,1.5,0,0,0,2.05-.54,1.54,1.54,0,0,0,.15-1.15l-.72-2.57-.11-.37a1.53,1.53,0,0,0-.69-.89,1.5,1.5,0,0,0-2,.54,1.54,1.54,0,0,0-.15,1.15Z" />
      </g>
    </svg>
  );
};

export default PigUnlocked;
