import React, { FunctionComponent } from "react";

const PigLocked: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g transform="translate(1.5 1.5)">
        <circle
          cx="38.5"
          cy="38.5"
          r="38.5"
          fill="#fff"
          stroke={`#${color}`}
          strokeWidth="3"
        />
        <path
          d="M22.38,34.16l4.09,4.09c1.67-.16,5.22-2.81,6.5-4.09L22.38,23.57a7.5,7.5,0,0,0,0,10.59Z"
          fill="#17aee5"
        />
        <path d="M21.32,35.22c1.06,1.06,2.11,2.12,3.17,3.17.29.29.57.6.87.87a1.91,1.91,0,0,0,2,.34A12.49,12.49,0,0,0,31,37.7a22.29,22.29,0,0,0,3-2.48,1.52,1.52,0,0,0,0-2.12l-9.27-9.27-1.32-1.32a1.53,1.53,0,0,0-2.12,0,9.1,9.1,0,0,0,0,12.71c1.35,1.39,3.47-.73,2.12-2.12a6.06,6.06,0,0,1,0-8.47H21.32l9.27,9.27,1.32,1.32V33.1a19,19,0,0,1-2.72,2.18,18.29,18.29,0,0,1-1.7,1l-.39.19c-.06,0-.34.14-.15.07l-.4.14c-.09,0-.48.09-.08,0l1.06.44L23.44,33.1a1.5,1.5,0,0,0-2.12,2.12Z" />
        <path
          d="M57.12,42A14.46,14.46,0,0,0,42.79,29.33H32.42a14.45,14.45,0,0,0-13.76,10H12.44v8.83h6.23a14.42,14.42,0,0,0,5.83,7.66V62.4H31V58.14a13.592,13.592,0,0,0,1.44.08H42.79a14.27,14.27,0,0,0,4.1-.6V62.4h6.48V53.61a14.44,14.44,0,0,0,3.86-9.84h0Z"
          fill="#17aee5"
        />
        <path d="M58.56,41.57a16.07,16.07,0,0,0-6.91-11,17.12,17.12,0,0,0-9.85-2.69H34.09A19.66,19.66,0,0,0,26,29.18,16.16,16.16,0,0,0,17.22,39l1.44-1.1H12.44a1.52,1.52,0,0,0-1.5,1.5v8.83a1.52,1.52,0,0,0,1.5,1.5h6.23l-1.45-1.1a16.6,16.6,0,0,0,6.52,8.56L23,55.85V62.4a1.52,1.52,0,0,0,1.5,1.5H31a1.52,1.52,0,0,0,1.5-1.5V58.14L31,59.64c2.48.22,5,.08,7.48.08,3,0,5.93.16,8.83-.65l-1.9-1.45V62.4a1.52,1.52,0,0,0,1.5,1.5h6.48a1.52,1.52,0,0,0,1.5-1.5V53.61l-.44,1.06A16.69,16.69,0,0,0,58.62,42c-.12-1.92-3.12-1.93-3,0A15.09,15.09,0,0,1,55,48.19a12.52,12.52,0,0,1-2.27,3.92A2.71,2.71,0,0,0,51.87,54c-.06,2.78,0,5.58,0,8.37l1.5-1.5H46.89l1.5,1.5V57.62a1.51,1.51,0,0,0-1.9-1.44c-2.68.75-5.51.54-8.27.54-2.4,0-4.85.13-7.24-.08a1.51,1.51,0,0,0-1.5,1.5V62.4L31,60.9H24.5L26,62.4V56.32a2.49,2.49,0,0,0-.13-1c-.37-.88-1.68-1.46-2.37-2.12a13.26,13.26,0,0,1-3.39-5.36,1.54,1.54,0,0,0-1.44-1.1H12.44l1.5,1.5V39.36l-1.5,1.5h6.22a1.54,1.54,0,0,0,1.45-1.1,13.17,13.17,0,0,1,6.64-7.63,15.24,15.24,0,0,1,7-1.3h7A17.35,17.35,0,0,1,48,31.93a13.25,13.25,0,0,1,7.63,10.44C55.94,44.27,58.83,43.46,58.56,41.57Z" />
        <path d="M28.15,37.56v-.22a1.14,1.14,0,0,0-.12-.58,1.21,1.21,0,0,0-.31-.48,1.11,1.11,0,0,0-.49-.31,1.06,1.06,0,0,0-.58-.13h-.39a1.47,1.47,0,0,0-.67.39l-.23.3a1.55,1.55,0,0,0-.21.76v.22a1.27,1.27,0,0,0,.13.58,1.32,1.32,0,0,0,.8.8,1.13,1.13,0,0,0,.57.12l.4-.05a1.54,1.54,0,0,0,.67-.38l.23-.31a1.42,1.42,0,0,0,.2-.76Z" />
        <path d="M44.75,29.45V24.09a7.74,7.74,0,0,1,2.72-6.4,7.29,7.29,0,0,1,8.25-.56,7.39,7.39,0,0,1,3.53,6.44c0,2,0,3.92,0,5.88a1.5,1.5,0,1,0,3,0V23.64A10.5,10.5,0,0,0,58,15.05a10.25,10.25,0,0,0-16.25,8.31v6.09a1.5,1.5,0,1,0,3,0Z" />
        <rect
          width="25.13"
          height="20.94"
          rx="2.22"
          transform="translate(39.44 29.39)"
          fill="#e73180"
        />
        <path d="M62.34,48.83H42.11c-.7,0-1.1-.17-1.17-.95-.26-2.92,0-6,0-8.89V33.87a11.28,11.28,0,0,1,0-2.25c.18-.91,1-.73,1.76-.73H61.36c.71,0,1.52-.16,1.7.73a15.93,15.93,0,0,1,0,3V46.2c0,.71.35,2.56-.72,2.63-1.91.14-1.93,3.14,0,3a3.84,3.84,0,0,0,3.72-4V32.74c0-1.55-.13-3-1.5-4.06a5.21,5.21,0,0,0-3.38-.75H50.52c-2.9,0-5.81-.08-8.7,0a3.83,3.83,0,0,0-3.88,3.74q0,1.5,0,3V46.12a10.26,10.26,0,0,0,.2,3.18,3.91,3.91,0,0,0,4,2.53c6.54.05,13.08,0,19.61,0h.58a1.5,1.5,0,1,0,.01-3Z" />
        <circle
          cx="2.82"
          cy="2.82"
          r="2.82"
          transform="translate(49.18 35.24)"
          fill="#e73180"
        />
        <path d="M53.32,38.06a1.851,1.851,0,0,1,0,.23v-.17a1,1,0,0,1-.09.37.74.74,0,0,1-.07.21s.17-.36.06-.15l-.23.38c-.15.23.21-.23.08-.1A1.592,1.592,0,0,0,53,39c-.1.11-.43.3,0,.06l-.3.2-.14.07c-.23.13.38-.12,0,0a2.42,2.42,0,0,1-.36.1h-.45c-.2,0,.41.07.13,0l-.22-.05-.22-.07c-.12,0-.12-.12.15.06l-.32-.17L51.1,39c-.16-.09.29.27.1.08s-.2-.23-.31-.32c.24.2.17.24.1.13l-.12-.19-.07-.12c-.07-.13-.08-.31,0,.08a1.573,1.573,0,0,0-.11-.35v-.15c-.07-.28,0,.4,0,0v-.35c0-.12.09-.34,0,.09v-.15a1.155,1.155,0,0,1,.11-.36c.08-.28-.18.33,0,.08l.11-.19c0-.06.07-.11.1-.16s.18-.12-.08.1c.09-.08.17-.19.25-.27s.24-.19,0,0h0s.27-.21.38-.21-.38.13-.15.06l.22-.07c.07,0,.46-.09.17-.05h.4c.28,0-.36-.07-.1,0l.44.12c.26.09-.28-.15-.12-.05a2.53,2.53,0,0,0,.23.12l.12.08c.32.22-.19-.18,0,0l.26.26c.2.2-.2-.31-.05-.07l.12.19.11.19c.12.23-.12-.35,0-.08a1.531,1.531,0,0,1,.13.43c.04.15,0,.2,0-.17v.32a1.54,1.54,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.5-1.5A4.39,4.39,0,0,0,53.41,34a4.329,4.329,0,1,0,2.91,4.09,1.5,1.5,0,0,0-1.5-1.5A1.52,1.52,0,0,0,53.32,38.06Z" />
        <path d="M50.5,41.9v3a1.54,1.54,0,0,0,.44,1.1,1.5,1.5,0,0,0,2.56-1.06v-3a1.54,1.54,0,0,0-.44-1.06A1.47,1.47,0,0,0,52,40.4a1.52,1.52,0,0,0-1.5,1.5Z" />
      </g>
    </svg>
  );
};

export default PigLocked;
