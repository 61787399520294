import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useContext,
  useMemo,
} from "react";
import ErrorPage from "../../features/ErrorPage/ErrorPage";
import { DateFormatter } from "../../helpers/DateFormatter";
import { Props } from "../../shared/intrefaces";
import { useAccountOverviewQuery } from "../AccountOverviewContext/useAccountOverviewQuery";
import { IPensionOverviewContext } from "./IPensionOverviewContext";
import { usePensionOverviewQuery } from "./usePensionOverviewQuery";

export const PensionOverviewContext = createContext<
  IPensionOverviewContext | undefined
>(undefined);
export const usePensionOverviewContext = () => {
  const context = useContext(PensionOverviewContext);

  if (!context) {
    throw new Error(
      "usePensionOverviewContext should be used within an PensionOverviewContext"
    );
  }

  return context;
};

export const PensionOverviewProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const { data: account } = useAccountOverviewQuery();
  const { isLoading, error, data, refetch } = usePensionOverviewQuery(
    !!account?.accounts.length
  );
  const isUploadedXml = data?.length > 1;
  const age = data?.[0]?.age?.toString();
  const month = data?.[0]?.month?.toString();
  const lastUpload = DateFormatter.utcToLocal(data?.[0]?.lastUpload);

  const value = useMemo(
    () => ({
      data,
      refetch,
      isUploadedXml,
      age,
      month,
      lastUpload,
    }),
    [data, isUploadedXml, age, month, lastUpload]
  );

  if (isLoading) {
    return <Fragment />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <PensionOverviewContext.Provider value={value}>
      {children}
    </PensionOverviewContext.Provider>
  );
};
