import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
} from "react";
import { useProcessDatesQuery } from "./useProcessDatesQuery";
import { IProcessDatesContext } from "./IProcessDatesContext";
import { Props } from "../../shared/intrefaces";

export const ProcessDates = createContext<IProcessDatesContext | undefined>(
  undefined
);
export const useProcessDates = () => {
  const context = useContext(ProcessDates);

  if (!context) {
    throw new Error("useProcessDates must be used within a ProcessDates");
  }

  return context;
};

export const ProcessDatesProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const { data: collectionData } = useProcessDatesQuery();

  const value = useMemo(
    () => ({
      collectionData,
    }),
    [collectionData]
  );

  return (
    <ProcessDates.Provider value={value}>{children}</ProcessDates.Provider>
  );
};
