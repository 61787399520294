import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const User: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon width={props.width} height={props.height}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 37 37"
        width={props.width}
        height={props.height}
      >
        <ellipse fill="#e73180" cx="18.5" cy="18.27" rx="17" ry="16.77" />
        <path
          fill="#010102"
          d="M34,18.27A15.44,15.44,0,0,1,23.79,32.63,15.82,15.82,0,0,1,6.55,28,15.21,15.21,0,0,1,5.17,10.47,15.71,15.71,0,0,1,21.53,3.29,15.51,15.51,0,0,1,34,18.27a1.5,1.5,0,0,0,3,0A18.53,18.53,0,0,0,24.9,1.13,18.75,18.75,0,0,0,4.32,6.54,18.28,18.28,0,0,0,2.67,27.72a18.8,18.8,0,0,0,19.49,8.46A18.53,18.53,0,0,0,37,18.27,1.5,1.5,0,0,0,34,18.27Z"
        />
        <ellipse fill="#ffffff" cx="18.5" cy="12.7" rx="4.94" ry="4.88" />
        <path
          fill="#010102"
          d="M21.94,12.7c0,.13,0,.27,0,.4,0,.3,0,.06,0,0,0,.22-.09.43-.15.64s-.08.25-.13.37c0-.07.12-.24,0,0s-.25.44-.4.66c.17-.25,0,0,0,0L21,15c-.07.07-.53.45-.28.27a4.93,4.93,0,0,1-.54.34,1.67,1.67,0,0,1-.23.12c-.15.08-.15.08,0,0l-.18.07c-.21.06-.42.13-.64.17s-.37.06-.06,0l-.34,0c-.23,0-.46,0-.69,0s.34.08-.07,0L17.56,16l-.37-.12c-.06,0-.26-.12,0,0a5.33,5.33,0,0,1-.57-.31,2,2,0,0,1-.26-.18c-.2-.14.24.24-.05,0s-.37-.35-.54-.54,0,0,0,0c-.07-.11-.15-.21-.21-.32l-.13-.23s-.2-.42-.07-.1a4.48,4.48,0,0,1-.2-.62l-.06-.26c0-.17,0-.17,0,0a1.5,1.5,0,0,1,0-.2,4.28,4.28,0,0,1,0-.62c0-.05.08-.48,0-.13,0-.09,0-.17,0-.26a6.36,6.36,0,0,1,.19-.62c.13-.39-.15.23,0-.12.07-.11.12-.23.19-.34s.11-.17.17-.26-.23.24,0,0,.29-.32.44-.46l.15-.13.16-.11a5.51,5.51,0,0,1,.67-.38c-.28.14.29-.09.38-.11l.26-.07s.42-.07.2,0,.21,0,.2,0h.35l.34,0c.36,0-.17-.06.14,0s.51.13.76.22-.16-.09.07,0,.27.13.4.21l.21.14a1.13,1.13,0,0,1,.21.15h0c.15.15.31.29.46.45s.27.37.08.09c.07.11.15.21.21.32s.12.23.19.34c.16.27-.06-.21.05.12s.15.49.22.75c0,.06,0,.29,0,0a4.75,4.75,0,0,1,0,.54,1.5,1.5,0,0,0,3,0A6.45,6.45,0,0,0,13.6,8.56,6.4,6.4,0,0,0,13,16a6.55,6.55,0,0,0,6.82,3,6.44,6.44,0,0,0,5.16-6.24,1.5,1.5,0,0,0-1.5-1.5A1.52,1.52,0,0,0,21.94,12.7Z"
        />
        <path
          fill="#ffffff"
          d="M27,32.9V30.11a7,7,0,0,0-7.07-7H17.09a7,7,0,0,0-7.07,7V32.9S18.71,38.75,27,32.9Z"
        />
        <path
          fill="#010102"
          d="M28.48,32.9c0-1.15.07-2.33,0-3.48a8.5,8.5,0,0,0-3.06-5.8,8.87,8.87,0,0,0-5.62-2,23.43,23.43,0,0,0-4.35.17A8.55,8.55,0,0,0,10,25.45a9,9,0,0,0-1.44,5.27c0,1.21-.31,2.78.85,3.54a16.41,16.41,0,0,0,3.13,1.51A16.25,16.25,0,0,0,27.73,34.2a1.55,1.55,0,0,0,.54-2.06,1.51,1.51,0,0,0-2.05-.54,13,13,0,0,1-10.63,2,14.24,14.24,0,0,1-4.81-2l.74,1.3a16.43,16.43,0,0,1,.17-4.09,5.61,5.61,0,0,1,4.41-4.08,18.27,18.27,0,0,1,4-.08A5.66,5.66,0,0,1,25.07,28c.63,1.49.41,3.28.41,4.86A1.5,1.5,0,0,0,28.48,32.9Z"
        />
      </svg>
    </ResizableIcon>
  );
};

export default User;
