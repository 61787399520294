import React, { Fragment, FunctionComponent } from "react";

import PigLocked from "./PigLocked";
import PigUnlocked from "./PigUnlocked";
import SuitCaseLocked from "./SuitCaseLocked";
import TombStoneLocked from "./TombStoneLocked";

import { IconEnum } from "./IconEnum";
import { ColorEnum } from "../../enums/ColorEnum";
import { IIcon } from "./IIcon";

import styles from "./Icon.module.scss";

const Icon: FunctionComponent<IIcon> = ({
  icon,
  color = `#${ColorEnum.Blue}`,
}) => {
  const renderIcon = () => {
    switch (icon) {
      case IconEnum.PigLocked:
        return <PigLocked color={color} />;
      case IconEnum.PigUnlocked:
        return <PigUnlocked color={color} />;
      case IconEnum.SuitCaseLocked:
        return <SuitCaseLocked color={color} />;
      case IconEnum.TombStoneLocked:
        return <TombStoneLocked color={color} />;
      default:
        return <Fragment />;
    }
  };

  return <div className={styles.Icon}>{renderIcon()}</div>;
};

export default Icon;
