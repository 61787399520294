import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const SignOut: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        width={props.width}
        height={props.height}
      >
        <circle fill="#ffffff" cx="30" cy="30" r="30" />
        <path d="M32.56,45.54H19.73c-1.51,0-3.05-.14-3.08-2.08,0-1.49,0-3,0-4.47V17.16c0-1.43.38-2.68,2.12-2.7,4.6-.07,9.2,0,13.79,0a1.5,1.5,0,0,0,0-3H19.15c-3,0-5.38,1.76-5.5,4.89-.05,1.39,0,2.8,0,4.19V37.67c0,1.85,0,3.7,0,5.54a5,5,0,0,0,4.87,5.33c4.67.18,9.37,0,14,0a1.5,1.5,0,0,0,0-3Z" />
        <path d="M33.91,21.18l9.88,9.88V28.94l-9.88,9.88A1.5,1.5,0,0,0,36,40.94l9.88-9.88a1.53,1.53,0,0,0,0-2.12L36,19.06a1.5,1.5,0,0,0-2.12,2.12Z" />
        <path d="M23.9,31.5H43.43a1.5,1.5,0,0,0,0-3H23.9a1.5,1.5,0,0,0,0,3Z" />
      </svg>
    </ResizableIcon>
  );
};

export default SignOut;
