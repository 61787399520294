import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import client from "../../api/client";
import { NotificationEnum } from "../../enums/NotificationEnum";
import { showNotification } from "../../helpers/NotificationPortal";
import { useTranslation } from "react-i18next";

const ENDPOINT = "EmployerPortalFutureInvestment";

const getFutureContributionByEmploymentIdQueryKey = (employmentId: number) => {
  return [employmentId];
};

const getFutureContributionByEmploymentId = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [employmentId] = queryKey as ReturnType<
    typeof getFutureContributionByEmploymentIdQueryKey
  >;

  const result = await client.get(`${ENDPOINT}?employmentId=${employmentId}`);

  return result.data.data;
};

const postFutureContribution = async (futureContributionData: any) => {
  await client.post(ENDPOINT, futureContributionData);
};

const putFutureContribution = async (futureContributionData: any) => {
  await client.put(ENDPOINT, futureContributionData);
};

const deleteFutureContribution = async (id: number) => {
  const request = `${ENDPOINT}?id=${id}`;
  await client.delete(request);
};

export const useFutureContributionQuery = (employmentId: number) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { isLoading, error, data, refetch } = useQuery<any, Error>(
    getFutureContributionByEmploymentIdQueryKey(employmentId),
    getFutureContributionByEmploymentId,
    {
      enabled: !!Boolean(employmentId),
    }
  );

  const addFutureContribution = useMutation(
    (futureContributionData: any) => {
      return postFutureContribution(futureContributionData);
    },
    {
      onError: (e: Error) => {
        showNotification(NotificationEnum.Danger, t("SomethingWentWrong"));
        console.error(e.message);
      },
      onSuccess: () => {
        showNotification(
          NotificationEnum.Success,
          t("FutureContributionAdded")
        );
        queryClient.invalidateQueries("addFutureContribution");
      },
    }
  );

  const updateFutureContribution = useMutation(
    (futureContributionData: any) => {
      return putFutureContribution(futureContributionData);
    },
    {
      onError: (e: Error) => {
        showNotification(NotificationEnum.Danger, t("SomethingWentWrong"));
        console.error(e.message);
      },
      onSuccess: () => {
        showNotification(
          NotificationEnum.Success,
          t("FutureContributionUpdated")
        );
        queryClient.invalidateQueries("updateFutureContribution");
      },
    }
  );

  const removeFutureContribution = useMutation(
    (id: number) => {
      return deleteFutureContribution(id);
    },
    {
      onError: (e: Error) => {
        showNotification(NotificationEnum.Danger, t("SomethingWentWrong"));
        console.error(e.message);
      },
      onSuccess: () => {
        showNotification(
          NotificationEnum.Success,
          t("FutureContributionRemoved")
        );
        queryClient.invalidateQueries("deleteFutureContribution");
      },
    }
  );

  return {
    addFutureContribution,
    isLoading,
    error,
    data,
    refetch,
    removeFutureContribution,
    updateFutureContribution,
  };
};
