import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import client from "../../api/client";
import { Props } from "../../shared/intrefaces";
import { ICreateXmlContext } from "./ICreateXmlContext";

export const CreateXmlContext = createContext<ICreateXmlContext | undefined>(
  undefined
);
export const useCreateXmlContext = (): ICreateXmlContext => {
  const context = useContext(CreateXmlContext);

  if (!context) {
    throw new Error(
      "useCreateXmlContext should be used within a CreateXmlContext"
    );
  }

  return context;
};

export const CreateXmlProvider: FunctionComponent<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const postXml = async (dataSendXml: any) => {
    const formData = new FormData();
    formData.append("xmlString", dataSendXml);
    const result = await client.post("/Portal/PensionImport", formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return result.data;
  };

  const [fileName, setFileName] = useState<string>("");
  const sendXml = useMutation(
    (dataSendXml: string) => {
      return postXml(dataSendXml);
    },
    {
      onError: (error: Error) => {
        console.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("pensionOverview");
      },
    }
  );

  const value = useMemo(
    () => ({
      sendXml,
      fileName,
      setFileName,
    }),
    [sendXml, fileName, setFileName]
  );

  return (
    <CreateXmlContext.Provider value={value}>
      {children}
    </CreateXmlContext.Provider>
  );
};
