import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const Close: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon width={props.width} height={props.height}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g transform="matrix(2.9998571,0,0,2.9998717,-3478.631,-291.43013)">
          <path
            fill="#000000"
            d="M 0,-0.5 A 0.5,0.5 0 0 0 -0.5,0 0.5,0.5 0 0 0 0,0.5 h 12.728516 a 0.5,0.5 0 0 0 0.5,-0.5 0.5,0.5 0 0 0 -0.5,-0.5 z"
            transform="rotate(-45,710.49213,-1347.7465)"
          />
          <path
            fill="#000000"
            d="M 0,-0.5 A 0.5,0.5 0 0 0 -0.5,0 0.5,0.5 0 0 0 0,0.5 h 12.726562 a 0.5,0.5 0 0 0 0.5,-0.5 0.5,0.5 0 0 0 -0.5,-0.5 z"
            transform="rotate(-135,607.34363,-188.51123)"
          />
        </g>
      </svg>
    </ResizableIcon>
  );
};

export default Close;
