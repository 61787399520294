import React, { FC, Fragment, useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { NotificationEnum } from "../enums/NotificationEnum";

interface IErrorNotificationProps {
  variant: string;
  message: string;
  horizontalDirection?: string;
  verticalDirection?: string;
  width?: string;
}

const Notification: FC<IErrorNotificationProps> = ({
  variant = "danger",
  message,
  verticalDirection = "top",
  horizontalDirection = "right",
  width = "25%",
}) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, []);

  const getNotificationVariant = (variant: string) => {
    switch (variant) {
      case NotificationEnum.Error:
      case NotificationEnum.Danger:
        return "#e73180";
      case NotificationEnum.Success:
        return "#6fc276";
    }
  };

  if (showAlert) {
    return (
      <Alert
        variant={variant.toLowerCase()}
        onClose={() => setShowAlert(false)}
        dismissible
        style={{
          position: "fixed",
          [verticalDirection]: 5,
          [horizontalDirection]: 5,
          zIndex: 10000,
          backgroundColor: getNotificationVariant(variant),
          width: width,
        }}
      >
        <Alert.Heading>{t(variant)}</Alert.Heading>
        <p>{t(message)}</p>
      </Alert>
    );
  }
  return <Fragment />;
};

export default Notification;
