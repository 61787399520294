import React, { FunctionComponent } from "react";
import { ISectionHeader } from "./ISectionHeader";

import styles from "./SectionHeader.module.scss";

const SectionHeader: FunctionComponent<ISectionHeader> = ({
  header,
  description,
}) => {
  return (
    <div className={styles.SectionHeader}>
      <h5 className={styles.RemoveMargins}>{header}</h5>
      <p className={styles.Content}>{description}</p>
    </div>
  );
};

export default SectionHeader;
