import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PdfTableRow } from "./PdfTableRow";
import { ProductTypeEnum } from "../../../../enums/ProductTypeEnum";
import { PlaceholderBox } from "../PlaceholderBox";

const styles = StyleSheet.create({
  table: {
    paddingLeft: 30,
    paddingRight: 30,
  },
} as const);

type PdfTableProps = {
  placeholder: PlaceholderBox;
};

export const PdfTable = ({ placeholder }: PdfTableProps) => {
  return (
    <View style={styles.table}>
      <PdfTableRow first>
        <Text> {placeholder.currentYear}</Text>
        <Text>{placeholder.valueAtEndOfPreviousYearTable}</Text>
      </PdfTableRow>
      <PdfTableRow>
        <Text>Inleg {placeholder.chosenYear}</Text>
        <Text>{placeholder.totalContributionTable}</Text>
      </PdfTableRow>
      <PdfTableRow>
        <Text>Vergoeding transactiekosten inkomend (0,05%)</Text>
        <Text>{placeholder.incomingTransactionCosts}</Text>
      </PdfTableRow>
      <PdfTableRow>
        <Text>Bijdrage variabel buffervermogen (0,02%)</Text>
        <Text>{placeholder.bufferContribution}</Text>
      </PdfTableRow>
      <PdfTableRow>
        <Text>Ontvangen dividend {placeholder.chosenYear} *</Text>
        <Text>{placeholder.dividendReceived}</Text>
      </PdfTableRow>
      {placeholder?.productType === ProductTypeEnum.Box3 && (
        <PdfTableRow>
          <Text>Ingehouden dividendbelasting</Text>
          <Text>{placeholder.dividendTaxWithheld}</Text>
        </PdfTableRow>
      )}
      <PdfTableRow>
        <Text> {placeholder.totalIncomingRedemption} </Text>
        <Text>{placeholder.totalIncomingRedemptionValue}</Text>
      </PdfTableRow>
      {placeholder?.productType === ProductTypeEnum.Box3 && (
        <PdfTableRow>
          <Text>Vergoeding transactiekosten uitgaand (0,05%)</Text>
          <Text>{placeholder.outgoingTransactionCosts}</Text>
        </PdfTableRow>
      )}
      <PdfTableRow>
        <Text>Beleggingsresultaat {placeholder.chosenYear}</Text>
        <Text>{placeholder.investmentResult}</Text>
      </PdfTableRow>
      <PdfTableRow last>
        <Text>Waarde op 31 december {placeholder.chosenYear}</Text>
        <Text>{placeholder.valueAtEndOfChosenYear}</Text>
      </PdfTableRow>
    </View>
  );
};
