import React, { FunctionComponent, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { IRoute, participantRoutes } from "./routes/routes";
import Header from "./features/Header/Header";
import Footer from "./features/Footer/Footer";
import { ParticipantSummaryProvider } from "./contexts/ParticipantSummary/ParticipantSummaryContext";
import styles from "./App.module.scss";
import ReusableJumbotron from "./features/ReusableJumbotron/ReusableJumbotron";
import { JumbotronProvider } from "./contexts/JumbotronContext/JumbotronContext";
import Document from "./features/Document/Document";
import ErrorBoundaryTracker from "./helpers/ErrorBoundaryTracker";
import { Authentication } from "./types/Authentication";
import { ModalPortal } from "./helpers/ModalPortal";
import { useModalContext } from "./contexts/ModalContext/ModalContext";

const Loading = () => <div>LOADING...</div>;

const App: FunctionComponent<{ auth: Authentication }> = ({ auth }) => {
  const [location, setLocation] = useState(window?.location?.pathname);

  const [routes, setRoutes] = useState<IRoute[]>(participantRoutes);
  const { modalData } = useModalContext();
  useEffect(() => {
    setRoutes(participantRoutes);
  }, [auth]);

  useEffect(() => {
    setLocation(window.location.pathname);
  }, [window.location.pathname]);

  const ErrorFallbackDocument = () => {
    useEffect(() => {
      window.location.reload();
    }, []);
    return (
      <>
        <Redirect to="/documenten" />;
      </>
    );
  };
  const ErrorFallback = () => {
    useEffect(() => {
      window.location.reload();
    }, []);
    return (
      <>
        <Redirect to="/" />;
      </>
    );
  };

  const Routes = () => {
    return location == "/document" ? (
      <>
        <Router>
          <ErrorBoundaryTracker FallbackComponent={ErrorFallbackDocument}>
            <Route
              key={1}
              path={"/document"}
              exact={true}
              render={() => {
                return <Document />;
              }}
            />
          </ErrorBoundaryTracker>
        </Router>
      </>
    ) : (
      <div className={styles.AppWrapper}>
        {modalData.show && <ModalPortal />}
        <div className={styles.Background} />
        <div className={styles.Main}>
          <Router>
            <ErrorBoundaryTracker FallbackComponent={ErrorFallback}>
              <JumbotronProvider>
                <ParticipantSummaryProvider providesHeader>
                  <Header />
                </ParticipantSummaryProvider>
                <ReusableJumbotron>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route, id) => {
                        return (
                          <Route
                            key={id}
                            path={route.path}
                            exact={route.exact}
                            render={(props: any) => (
                              <route.component {...props} />
                            )}
                          />
                        );
                      })}
                    </Switch>
                  </Suspense>
                </ReusableJumbotron>
              </JumbotronProvider>
              <Redirect to="/" />
            </ErrorBoundaryTracker>
          </Router>
        </div>
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    );
  };

  return auth.isLoggedIn ? Routes() : <div>Loading...</div>;
};

export default App;
