import React, { FunctionComponent } from "react";

const TombStoneLocked: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g transform="translate(1.5 1.5)">
        <path
          d="M38.5,0A38.5,38.5,0,1,1,0,38.5,38.5,38.5,0,0,1,38.5,0Z"
          fill="#fff"
          stroke={color}
          strokeWidth="3"
        />
        <path
          d="M51.31,27.66a13.4,13.4,0,1,0-26.79,0V55.48H51.45S51.31,31.55,51.31,27.66Z"
          fill="#17aee5"
        />
        <path d="M52.81,27.66a14.89,14.89,0,0,0-28.43-6.23C22.87,24.79,23,28.22,23,31.79V55.48a1.52,1.52,0,0,0,1.5,1.5H51.45a1.51,1.51,0,0,0,1.5-1.5l-.12-22.74V27.66a1.5,1.5,0,1,0-3,0c0,8.25.08,16.51.12,24.76v3.06l1.5-1.5H24.52l1.5,1.5V27.65a11.9,11.9,0,1,1,23.79,0C49.84,29.59,52.84,29.6,52.81,27.66Z" />
        <rect
          width="33.34"
          height="6.17"
          transform="translate(21.83 55.51)"
          fill="#17aee5"
        />
        <path d="M21.83,57H55.17l-1.5-1.5v6.16l1.5-1.5H21.83l1.5,1.5V55.51a1.5,1.5,0,0,0-3,0v6.16a1.52,1.52,0,0,0,1.5,1.5H55.17a1.52,1.52,0,0,0,1.5-1.5V55.51a1.52,1.52,0,0,0-1.5-1.5H21.83a1.5,1.5,0,0,0,0,2.99Z" />
        <path d="M31.87,30.91H44.1a1.5,1.5,0,1,0,0-3H31.87a1.5,1.5,0,0,0,0,3Z" />
        <path d="M31.87,36.19H44.1a1.5,1.5,0,1,0,0-3H31.87a1.5,1.5,0,0,0,0,3Z" />
        <path d="M44.75,29.45V24.09a7.74,7.74,0,0,1,2.72-6.4,7.29,7.29,0,0,1,8.25-.56,7.39,7.39,0,0,1,3.53,6.44c0,2,0,3.92,0,5.88a1.5,1.5,0,1,0,3,0V23.64A10.5,10.5,0,0,0,58,15.05a10.25,10.25,0,0,0-16.25,8.31v6.09a1.5,1.5,0,1,0,3,0Z" />
        <rect
          width="25.13"
          height="20.94"
          rx="2.22"
          transform="translate(39.44 29.39)"
          fill="#e73180"
        />
        <path d="M62.34,48.83H42.11c-.7,0-1.1-.17-1.17-.95-.26-2.92,0-6,0-8.89V33.87a11.28,11.28,0,0,1,0-2.25c.18-.91,1-.73,1.76-.73H61.36c.71,0,1.52-.16,1.7.73a15.93,15.93,0,0,1,0,3V46.2c0,.71.35,2.56-.72,2.63-1.91.14-1.93,3.14,0,3a3.84,3.84,0,0,0,3.72-4V32.74c0-1.55-.13-3-1.5-4.06a5.21,5.21,0,0,0-3.38-.75H50.52c-2.9,0-5.81-.08-8.7,0a3.83,3.83,0,0,0-3.88,3.74q0,1.5,0,3V46.12a10.26,10.26,0,0,0,.2,3.18,3.91,3.91,0,0,0,4,2.53c6.54.05,13.08,0,19.61,0h.58a1.5,1.5,0,1,0,.01-3Z" />
        <circle
          cx="2.82"
          cy="2.82"
          r="2.82"
          transform="translate(49.18 35.24)"
          fill="#e73180"
        />
        <path d="M53.32,38.06a1.851,1.851,0,0,1,0,.23v-.17a1,1,0,0,1-.09.37.74.74,0,0,1-.07.21s.17-.36.06-.15l-.23.38c-.15.23.21-.23.08-.1A1.592,1.592,0,0,0,53,39c-.1.11-.43.3,0,.06l-.3.2-.14.07c-.23.13.38-.12,0,0a2.42,2.42,0,0,1-.36.1h-.45c-.2,0,.41.07.13,0l-.22-.05-.22-.07c-.12,0-.12-.12.15.06l-.32-.17L51.1,39c-.16-.09.29.27.1.08s-.2-.23-.31-.32c.24.2.17.24.1.13l-.12-.19-.07-.12c-.07-.13-.08-.31,0,.08a1.573,1.573,0,0,0-.11-.35v-.15c-.07-.28,0,.4,0,0v-.35c0-.12.09-.34,0,.09v-.15a1.155,1.155,0,0,1,.11-.36c.08-.28-.18.33,0,.08l.11-.19c0-.06.07-.11.1-.16s.18-.12-.08.1c.09-.08.17-.19.25-.27s.24-.19,0,0h0s.27-.21.38-.21-.38.13-.15.06l.22-.07c.07,0,.46-.09.17-.05h.4c.28,0-.36-.07-.1,0l.44.12c.26.09-.28-.15-.12-.05a2.53,2.53,0,0,0,.23.12l.12.08c.32.22-.19-.18,0,0l.26.26c.2.2-.2-.31-.05-.07l.12.19.11.19c.12.23-.12-.35,0-.08a1.531,1.531,0,0,1,.13.43c.04.15,0,.2,0-.17v.32a1.54,1.54,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.5-1.5A4.39,4.39,0,0,0,53.41,34a4.329,4.329,0,1,0,2.91,4.09,1.5,1.5,0,0,0-1.5-1.5A1.52,1.52,0,0,0,53.32,38.06Z" />
        <path d="M50.5,41.9v3a1.54,1.54,0,0,0,.44,1.1,1.5,1.5,0,0,0,2.56-1.06v-3a1.54,1.54,0,0,0-.44-1.06A1.47,1.47,0,0,0,52,40.4a1.52,1.52,0,0,0-1.5,1.5Z" />
      </g>
    </svg>
  );
};

export default TombStoneLocked;
