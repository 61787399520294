import React, { FunctionComponent } from "react";
import { ColorEnum } from "../../../enums/ColorEnum";
import { IResizableIcon } from "./IResizableIcon";
import styles from "./ResizableIcon.module.scss";

const ResizableIcon: FunctionComponent<IResizableIcon> = ({
  bordered,
  borderColor,
  children,
}) => {
  const color = borderColor ? borderColor : ColorEnum.Black;

  const style = bordered
    ? styles[`ResizableIconBordered-${color}`]
    : styles.ResizableIcon;

  return <div className={style}>{children}</div>;
};

export default ResizableIcon;
