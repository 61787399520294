import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProductTypeEnum } from "../../enums/ProductTypeEnum";
import { DateFormatter } from "../../helpers/DateFormatter";
import { toEuroString, toEuroStringDocument } from "../../helpers/toEuroString";
import BoxPdf from "./Box/BoxPdf";
import { PlaceholderBox } from "./Box/PlaceholderBox";
import CertificatePdf from "./Certificate/CertificatePdf";
import stylesPDF from "./Document.module.scss";

const Document: FunctionComponent = () => {
  const dataFromStorage = localStorage.getItem("dataPDF");
  const data = JSON.parse(dataFromStorage ? dataFromStorage : "");
  const { t } = useTranslation();
  const showPlaceholderData = (...args: any[]) => {
    return args.map((singleProperty: any) => {
      const checkIsFalse = !!singleProperty ? singleProperty : "";
      return `${singleProperty == 0 ? 0 : checkIsFalse}`;
    });
  };
  const showFullName = (
    firstName: string,
    prefix: string,
    lastName: string
  ) => {
    const withoutPrefix = `${firstName} ${lastName}`;
    const withPrefix = `${firstName} ${prefix} ${lastName}`;

    if (!prefix) {
      return withoutPrefix;
    }
    return prefix.trim() ? withPrefix : withoutPrefix;
  };

  const showAddress = (
    street: string,
    houseNumber: number,
    addition: string
  ) => {
    const withoutAddition = `${street} ${houseNumber}`;
    const withAddition = `${street} ${houseNumber} ${addition}`;

    if (!addition) {
      return withoutAddition;
    }
    return addition.trim() ? withAddition : withoutAddition;
  };

  const placeholderBox: PlaceholderBox = {
    productType: data?.ProductType,
    boxName: t(data?.ProductType),
    currentYear:
      data?.ProductType === ProductTypeEnum.Box1
        ? `Waarde 31 december ${data?.PreviousYear}`
        : `Waarde 1 januari ${data?.ChosenYear}`,
    fullName: showFullName(data?.FirstName, data?.Prefix, data?.LastName),
    firstName: data?.FirstName,
    accountNumber: showPlaceholderData(data?.AccountNumber).join(" "),
    productName: data?.ProductName,
    address: showAddress(data?.Street, data?.HouseNumber, data?.Addition),
    cityPostCode: showPlaceholderData(data?.Postcode, data?.City).join(" "),
    creationDate: showPlaceholderData(
      DateFormatter.utcToLocal(data?.DateCreated)
    ).join(" "),
    chosenYear: showPlaceholderData(data?.ChosenYear).join(" "),
    valueAtEndOfPreviousYear: showPlaceholderData(
      toEuroString(
        data?.ProductType === ProductTypeEnum.Box1
          ? Math.ceil(data?.ValueAtEndOfPreviousYear)
          : Math.floor(data?.ValueAtEndOfPreviousYear)
      )
    ).join(" "),
    valueAtEndOfPreviousYearTable: showPlaceholderData(
      toEuroStringDocument(data?.ValueAtEndOfPreviousYear)
    ).join(" "),
    valueAtEndOfChosenYear: showPlaceholderData(
      toEuroString(data?.ValueAtEndOfChosenYear)
    ).join(" "),
    totalContributionTable: showPlaceholderData(
      toEuroStringDocument(data?.TotalContribution)
    ).join(" "),
    totalContribution: showPlaceholderData(
      toEuroString(
        data?.ProductType === ProductTypeEnum.Box1
          ? Math.ceil(data?.TotalContribution)
          : Math.floor(data?.TotalContribution)
      )
    ).join(" "),
    incomingTransactionCosts: showPlaceholderData(
      toEuroStringDocument(data?.IncomingTransactionCosts)
    ).join(" "),
    bufferContribution: showPlaceholderData(
      toEuroStringDocument(data?.BufferContribution)
    ).join(" "),
    totalRedemptions: showPlaceholderData(
      toEuroStringDocument(data?.TotalRedemptions)
    ).join(" "),
    outgoingTransactionCosts: showPlaceholderData(
      toEuroStringDocument(data?.OutgoingTransactionCosts)
    ).join(" "),
    investmentResult: showPlaceholderData(
      toEuroStringDocument(data?.InvestmentResult)
    ).join(" "),
    textInBox:
      data?.ProductType === ProductTypeEnum.Box1
        ? `Uitgaven lijfrenten en andere inkomensvoorzieningen – Inleg ${showPlaceholderData(
            data?.ChosenYear
          )}`
        : `Beleggingen – Andere belegging – BrightBeleggen (waarde 01/01/${showPlaceholderData(
            data?.ChosenYear
          )})`,
    totalIncomingRedemption:
      data?.ProductType === ProductTypeEnum.Box1
        ? "Inkomende waardeoverdrachten "
        : `Onttrekkingen ${showPlaceholderData(data?.ChosenYear)}`,
    totalIncomingRedemptionValue:
      data?.ProductType === ProductTypeEnum.Box1
        ? showPlaceholderData(
            toEuroStringDocument(data?.TotalIncomingValueTransfers)
          ).join(" ")
        : showPlaceholderData(
            toEuroStringDocument(data?.TotalRedemptions)
          ).join(" "),
    outgoingTransaction:
      data?.ProductType === ProductTypeEnum.Box3
        ? () => {
            return (
              <p className={stylesPDF.row}>
                Vergoeding transactiekosten uitgaand (0,05%)
                <span>{placeholderBox.outgoingTransactionCosts}</span>
              </p>
            );
          }
        : () => null,
    dividendReceived: showPlaceholderData(
      toEuroStringDocument(data?.DividendReceived)
    ).join(" "),
    dividendTaxWithheld: showPlaceholderData(
      toEuroStringDocument(data?.DividendTaxWithheld)
    ).join(" "),
    dividendTaxWhithheldPositive: showPlaceholderData(
      // First round to 2 decimals, then ceil to full euro.
      // This is to prevent the table from showing 0,00 and the box to show 1.
      toEuroString(
        Math.ceil(Math.round(data?.DividendTaxWithheld * -100) / 100),
        false,
        0
      )
    ).join(" "),
  };

  const certificateNewArray = () => {
    const certificateArray = data?.Certificates;
    const numberOfPages =
      (certificateArray.length - (certificateArray.length % 5)) / 5 + 1;
    const perGroup = 5;
    return new Array(numberOfPages)
      .fill("")
      .map((_, i) => certificateArray.slice(i * perGroup, (i + 1) * perGroup));
  };

  const placeholderCertificate = {
    fullName: showFullName(data?.FirstName, data?.Prefix, data?.LastName),
    certifaicatesPerPages: !!data?.Certificates && certificateNewArray(),
  };

  const filename = !!placeholderBox?.productType
    ? `${data?.ProductType}_${data?.CreationDate}.pdf`
    : `Certificates_${DateFormatter.utcToLocal(data?.DateCreated)}.pdf`;

  const pdfPreviewDocument = useMemo(
    () =>
      !!placeholderBox?.productType ? (
        <BoxPdf placeholder={placeholderBox} />
      ) : (
        <CertificatePdf placeholder={placeholderCertificate} />
      ),
    [placeholderBox, placeholderCertificate]
  );

  return (
    <>
      <PDFDownloadLink document={pdfPreviewDocument} fileName={filename}>
        <button className={stylesPDF.download}>{t("Download")}</button>
      </PDFDownloadLink>
      {navigator.pdfViewerEnabled ? (
        <PDFViewer className={stylesPDF.pdfViewer} showToolbar={false}>
          {pdfPreviewDocument}
        </PDFViewer>
      ) : (
        t("PdfViewerNotEnabled")
      )}
    </>
  );
};

export default Document;
