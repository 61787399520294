import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const Add: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon
      width={props.width}
      height={props.height}
      bordered={props.bordered}
      borderColor={props.borderColor}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        width={props.width}
        height={props.height}
      >
        <path
          fill="#010102"
          d="M28.5,19.76V40.24a1.5,1.5,0,0,0,3,0V19.76a1.5,1.5,0,0,0-3,0Z"
        />
        <path
          fill="#010102"
          d="M19.76,31.5H40.24a1.5,1.5,0,0,0,0-3H19.76a1.5,1.5,0,0,0,0,3Z"
        />
        <path
          fill="#010102"
          d="M46.73,30.24a16.73,16.73,0,0,1-29.81,10.2A16.73,16.73,0,0,1,33.43,13.62a16.92,16.92,0,0,1,13.3,16.62,1.5,1.5,0,0,0,3,0A19.73,19.73,0,0,0,14.9,17.31,19.72,19.72,0,0,0,33.58,49.4,19.92,19.92,0,0,0,49.73,30.24C49.77,28.31,46.77,28.31,46.73,30.24Z"
        />
      </svg>
    </ResizableIcon>
  );
};

export default Add;
