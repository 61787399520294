import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useContext,
  useMemo,
  useState,
} from "react";
import ErrorPage from "../../features/ErrorPage/ErrorPage";
import { Props } from "../../shared/intrefaces";
import { IAccountOverviewContext } from "./IAccountOverviewContext";
import { useAccountOverviewQuery } from "./useAccountOverviewQuery";

export const AccountOverviewContext = createContext<
  IAccountOverviewContext | undefined
>(undefined);
export const useAccountOverviewContext = () => {
  const context = useContext(AccountOverviewContext);

  if (!context) {
    throw new Error(
      "useAccountOverviewContext should be used within an AccountOverviewContext"
    );
  }

  return context;
};

export const AccountOverviewProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const { isLoading, error, data, refetch } = useAccountOverviewQuery();

  const [showSavedModalFirstTime, setShowSavedModalFirstTime] = useState(true);

  const value = useMemo(
    () => ({
      data,
      refetch,
      showSavedModalFirstTime,
      setShowSavedModalFirstTime,
    }),
    [data, showSavedModalFirstTime, setShowSavedModalFirstTime]
  );

  if (isLoading) {
    return <Fragment />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <AccountOverviewContext.Provider value={value}>
      {children}
    </AccountOverviewContext.Provider>
  );
};
