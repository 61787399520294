import moment from "moment";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParticipantSummaryContext } from "../../../contexts/ParticipantSummary/ParticipantSummaryContext";
import { nameFromAccountOrSummary } from "../../../helpers/nameFromAccount";

import styles from "./ParticipantSummary.module.scss";

const ParticipantSummary: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: summary } = useParticipantSummaryContext();

  const renderParticipantNumber = () => {
    if (!summary) return <Fragment />;
    const content = `${t("ParticipantNumber")} ${summary.participantNumber}`;

    return <p className={styles.ParticipantNumber}>{content}</p>;
  };

  const renderMemberSince = () => {
    if (!summary) return <Fragment />;
    const content =
      t("MemberSince") + " " + moment(summary.memberSince).format("DD-MM-YYYY");

    return <p className={styles.Content}>{content}</p>;
  };

  const render = () => {
    if (!summary) return <Fragment />;

    return (
      <div className={styles.ParticipantSummary}>
        <h3 className={styles.Pink}>{nameFromAccountOrSummary(summary)}</h3>
        {renderParticipantNumber()}
        {renderMemberSince()}
      </div>
    );
  };

  return <Fragment>{render()}</Fragment>;
};

export default ParticipantSummary;
