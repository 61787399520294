import React, { createPortal, render } from "react-dom";
import { NotificationEnum } from "../enums/NotificationEnum";
import Notification from "../shared/Notification";

export const showNotification = (
  variant: NotificationEnum,
  message: string,
  wrapperId = "react-portal-wrapper"
) => {
  let element = document.getElementById(wrapperId);

  if (!element) {
    element = createWrapperAndAppendToBody(wrapperId);
  }

  return render(
    createPortal(<Notification variant={variant} message={message} />, element),
    document.createElement("div")
  );
};

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}
