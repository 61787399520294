import React, { FunctionComponent } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { IMoneyInput } from "./IMoneyInput";

import styles from "./MoneyInput.module.scss";

const MoneyInput: FunctionComponent<IMoneyInput> = ({
  label,
  value,
  onValueChange,
  bordered,
  alignRight,
}) => {
  const renderLabel = (): JSX.Element | null => {
    if (label) {
      return (
        <label data-testid="money-input-label" className={styles.Label}>
          {label}
        </label>
      );
    }
    return null;
  };

  return (
    <div className={styles.Wrapper}>
      <div className={bordered ? styles.MoneyInputBordered : styles.MoneyInput}>
        {renderLabel()}
        <NumberFormat
          className={alignRight ? "text-right" : ""}
          data-testid="money-input"
          value={value}
          decimalScale={2}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          prefix="€ "
          fixedDecimalScale
          onValueChange={(values: NumberFormatValues) => {
            onValueChange(values.floatValue ?? 0);
          }}
        />
      </div>
    </div>
  );
};

export default MoneyInput;
