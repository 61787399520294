import { useQuery } from "react-query";
import client from "../../../api/client";
import Configuration from "./IConfiguration";

const fetchConfiguration = async () => {
  const result = await client.get("/configuration/portal");
  return result.data;
};

const fetchFeatureToggle = async () => {
  const result = await client.get("/configuration/features");
  return result.data;
};

export const useConfiguration = () => {
  const { isLoading, status, error, data } = useQuery<
    boolean,
    Error,
    Configuration
  >("configuration", fetchConfiguration);
  return { isLoading, status, error, data };
};

export const useFeatureToggle = () => {
  const { isLoading, status, error, data } = useQuery<
    boolean,
    Error,
    Configuration
  >("fetureToggle", fetchFeatureToggle);

  const featureToggle = data;
  return { isLoading, status, error, featureToggle };
};
