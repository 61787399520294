import { StyleSheet, Text } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { PropsWithChildren } from "react";

const styles = StyleSheet.create({
  header: {
    color: "#17aee5",
    fontSize: 12,
  },
  bold: {
    fontFamily: "NexaSlabHeavy",
  },
  center: {
    textAlign: "center",
  },
} as const);

type PdfHeaderProps = {
  style?: Style;
  bold?: boolean;
  center?: boolean;
};

export const PdfHeader = ({
  style,
  bold,
  center,
  children,
}: PropsWithChildren<PdfHeaderProps>) => {
  const headerStyles: Style[] = [styles.header];
  if (bold) {
    headerStyles.push(styles.bold);
  }

  if (center) {
    headerStyles.push(styles.center);
  }

  if (style) {
    headerStyles.push(style);
  }

  return <Text style={headerStyles}>{children}</Text>;
};
