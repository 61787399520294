import { ProductTypeEnum } from "../../../../enums/ProductTypeEnum";
import { PdfHeader } from "./PdfHeader";
import { PdfLink } from "./PdfLink";
import { PdfText } from "./PdfText";

type PdfExplanationDividendProps = {
  productType?: ProductTypeEnum;
};

const PdfExplanationDividendBox1 = () => (
  <>
    <PdfText>Het ontvangen dividend is herbelegd in het fonds.</PdfText>
    <PdfText noMargin>
      Over het ontvangen dividend op deze rekening is geen dividendbelasting
      ingehouden.
    </PdfText>
    <PdfText>
      Dit is in dit geval niet verplicht. Voor meer informatie over het
      dividend, zie{" "}
      <PdfLink src="https://brightpensioen.nl/didividenduiterking-brightlifecyclefonds/">
        onze website
      </PdfLink>
      .
    </PdfText>
  </>
);

const PdfExplanationDividendBox3 = () => (
  <>
    <PdfText>
      Het ontvangen dividend (na inhouding van dividendbelasting) is herbelegd
      in het fonds.
    </PdfText>
    <PdfText>
      De dividendbelasting kan worden verrekend bij de aangifte
      inkomstenbelasting. Zie voor een uitgebreidere toelichting{" "}
      <PdfLink src="https://brightpensioen.nl/financieel-jaaroverzicht-brightpensioen/">
        onze website
      </PdfLink>
      .
    </PdfText>
  </>
);

export const PdfExplanationDividend = ({
  productType,
}: PdfExplanationDividendProps) => (
  <>
    <PdfHeader>* Toelichting dividend</PdfHeader>
    {productType === ProductTypeEnum.Box1 ? (
      <PdfExplanationDividendBox1 />
    ) : (
      <PdfExplanationDividendBox3 />
    )}
  </>
);
