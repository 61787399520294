import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
} from "react";

import { IFutureContributionContext } from "./IFutureContributionContext";
import { useFutureContributionQuery } from "./useFutureContributionQuery";

export const FutureContributionContext = createContext<
  IFutureContributionContext | undefined
>(undefined);

export const useFutureContributionContext = () => {
  const context = useContext(FutureContributionContext);

  if (!context) {
    throw new Error(
      "useEmploymentContext must be used within an EmploymentContext"
    );
  }

  return context;
};

interface Props {
  children: any;
  employmentId: number;
}

export const FutureContributionProvider: FunctionComponent<Props> = ({
  children,
  employmentId,
}) => {
  const {
    addFutureContribution,
    isLoading,
    error,
    data,
    removeFutureContribution,
    updateFutureContribution,
  } = useFutureContributionQuery(employmentId);

  const value = useMemo(
    () => ({
      isLoading,
      error,
      futureContribution: data,
      addFutureContribution,
      removeFutureContribution,
      updateFutureContribution,
    }),
    [data]
  );

  return (
    <FutureContributionContext.Provider value={value}>
      {children}
    </FutureContributionContext.Provider>
  );
};
