import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMutation } from "react-query";
import client from "../../api/client";
import { Props } from "../../shared/intrefaces";
import { PdfData } from "../../types/PdfCreation";
import { ICreatePdfContext } from "./ICreatePdfContext";

export const CreatePDFContext = createContext<ICreatePdfContext | undefined>(
  undefined
);
export const useCreatePDFContext = (): ICreatePdfContext => {
  const context = useContext(CreatePDFContext);

  if (!context) {
    throw new Error(
      "useCreatePDFContext should be used within a CreatePDFContext"
    );
  }

  return context;
};

export const CreatePDFProvider: FunctionComponent<Props> = ({ children }) => {
  const postCreatePDF = async (dataCreatePdf: any) => {
    const result = await client.post("/Portal/PortalExport", dataCreatePdf);
    return result.data;
  };

  const [responseData, setResponseData] = useState("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const createPDF = useMutation(
    (dataCreatePdf: PdfData) => {
      return postCreatePDF(dataCreatePdf);
    },
    {
      onError: (error: Error) => {
        console.error(error as any);
      },
      onSuccess: () => {
        setIsCreating(true);
      },
      onSettled: () => {
        setIsCreating(false);
      },
    }
  );

  useEffect(() => {
    createPDF.data && setResponseData(createPDF.data);
  }, [createPDF.isSuccess]);

  const value = useMemo(
    () => ({
      createPDF,
      responseData,
      isCreating,
      setIsCreating,
    }),
    [createPDF, responseData, isCreating]
  );

  return (
    <CreatePDFContext.Provider value={value}>
      {children}
    </CreatePDFContext.Provider>
  );
};
