import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Background from "../../../images/Certificate.png";
import { useTranslation } from "react-i18next";

Font.register({
  family: "NexaSlabHeavy",
  src: "/NexaSlabHeavy.ttf",
  format: "truetype",
  fontWeight: 900,
});

Font.register({
  family: "MuseoSansRounded",
  src: "/MuseoSansRounded-300.ttf",
  format: "truetype",
});

const CertificatePdf = ({ placeholder }: any) => {
  const { t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
      width: "100%",
      fontFamily: "MuseoSansRounded",
    },
    section: {
      paddingTop: 5,
      height: 1332,
      width: "100%",
    },
    image: {
      width: 850,
      position: "absolute",
      minWidth: "100%",
      height: 600,
    },
    container: {
      paddingRight: 150,
      paddingLeft: 150,
      paddingTop: 210,
      width: 850,
    },

    user: {
      marginBottom: 12,
      fontSize: 36,
      borderBottom: "1pt solid #17aee5",
      textAlign: "center",
    },

    row: {
      flexDirection: "row",
      borderBottom: "1pt solid #17aee5",
      fontSize: 16,
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 8,
      paddingTop: 8,
    },
    rowFirst: {
      flexDirection: "row",
      fontSize: 18,
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 8,
      paddingTop: 8,
      fontFamily: "NexaSlabHeavy",
      color: "#17aee5",
    },
    type: {
      width: 180,
    },
    year: {
      marginLeft: -120,
    },
    number: {
      width: 30,
      textAlign: "right",
    },
  });

  const renderCertificate = () => {
    return placeholder?.certifaicatesPerPages?.map(
      (certificate: any, index: number) => {
        return (
          <>
            <Page orientation="landscape" size="A4" style={styles.page}>
              <View style={styles.container}>
                <Image style={styles.image} src={Background} />
                <View key={index}>
                  <View>
                    <Text style={styles.user}>{placeholder?.fullName}</Text>
                    <View style={styles.rowFirst}>
                      <Text>Type certificaten</Text>
                      <Text>Jaar uitgifte</Text>
                      <Text>Aantal</Text>
                    </View>
                    {certificate.map(
                      (singleCertificate: any, indexInner: number) => {
                        return (
                          <View key={indexInner} style={styles.row}>
                            <Text style={styles.type}>
                              {t(singleCertificate?.Type)}
                            </Text>
                            <Text style={styles.year}>
                              {singleCertificate?.Year}
                            </Text>
                            <Text style={styles.number}>
                              {singleCertificate?.Number}
                            </Text>
                          </View>
                        );
                      }
                    )}
                  </View>
                </View>
              </View>
            </Page>
          </>
        );
      }
    );
  };

  return (
    <>
      <Document>{renderCertificate()}</Document>
    </>
  );
};

export default CertificatePdf;
