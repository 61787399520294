import React, { FunctionComponent } from "react";
import styles from "./Divider.module.scss";

const Divider: FunctionComponent<{ isPink?: boolean }> = ({ isPink }) => {
  const getStyling = () => {
    if (isPink) {
      return styles.PinkDivider;
    }
    return styles.Divider;
  };

  return <div className={getStyling()} />;
};

export default Divider;
