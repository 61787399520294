import React, { FunctionComponent, useState } from "react";
import { useJumbotronContext } from "../../contexts/JumbotronContext/JumbotronContext";
import i18n from "../../i18n";

import styles from "./LanguageSelector.module.scss";

const LanguageSelector: FunctionComponent = () => {
  const { setLanguage } = useJumbotronContext();

  const [activeLanguage, setActiveLanguage] = useState<string>(
    localStorage.getItem("langData") ?? "nl"
  );

  const getStyling = (language: "nl" | "en") => {
    switch (language) {
      case "nl":
        return activeLanguage === "nl"
          ? `${styles.Dutch} ${styles.Active}`
          : styles.Dutch;
      case "en":
        return activeLanguage === "en"
          ? `${styles.English} ${styles.Active}`
          : styles.English;
    }
  };

  const handleClick = (language: "nl" | "en") => {
    setActiveLanguage(language);
    localStorage.setItem("langData", language);
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={getStyling("nl")} onClick={() => handleClick("nl")}>
        NL
      </div>
      <div>|</div>
      <div className={getStyling("en")} onClick={() => handleClick("en")}>
        EN
      </div>
    </div>
  );
};

export default LanguageSelector;
