import { Account } from "../types/Account";
import { ParticipantSummary } from "../types/ParticipantSummary";

export const nameFromAccountOrSummary = (
  account?: Account | ParticipantSummary
): string => {
  if (!account) {
    return "";
  }

  if (account.prefix) {
    return `${account.initials} ${account.prefix} ${account.lastName}`;
  }
  return `${account.initials} ${account.lastName}`;
};
