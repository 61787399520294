export const useCookieBot = (domainGroupId: string, language: string) => {
  if (!window || !document) {
    throw new Error("DOM is not available");
  }

  if (!domainGroupId) {
    throw new Error("CookieBot needs a domain group id to function.");
  }

  const cookieBotScript = document.createElement("script");
  cookieBotScript.setAttribute("id", "CookieBot");
  cookieBotScript.setAttribute("src", "https://consent.cookiebot.com/uc.js");
  cookieBotScript.setAttribute("data-cbid", domainGroupId);
  cookieBotScript.setAttribute("data-blockingmode", "auto");
  cookieBotScript.setAttribute("type", "text/javascript");
  cookieBotScript.setAttribute("data-culture", language);

  const head = document.querySelector("html > head") as Element;
  head.insertBefore(cookieBotScript, head.firstChild);
};
