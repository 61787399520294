import { StyleSheet, Text } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { PropsWithChildren } from "react";

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    marginBottom: 12,
  },
  noMargin: {
    marginBottom: 0,
  },
} as const);

type PdfTextProps = {
  style?: Style;
  noMargin?: boolean;
};

export const PdfText = ({
  style,
  noMargin,
  children,
}: PropsWithChildren<PdfTextProps>) => {
  const textStyles: Style[] = [styles.text];
  if (noMargin) {
    textStyles.push(styles.noMargin);
  }
  if (style) {
    textStyles.push(style);
  }

  return <Text style={textStyles}>{children}</Text>;
};
