import React, { FunctionComponent, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useJumbotronContext } from "../../contexts/JumbotronContext/JumbotronContext";
import { ColorEnum } from "../../enums/ColorEnum";

import styles from "./ErrorPage.module.scss";
import generalStyles from "../../scss/General.module.scss";

const ErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { language, setJumbotronProps } = useJumbotronContext();

  useEffect(() => {
    setJumbotronProps({
      color: ColorEnum.Grey,
      header: t("ErrorHeader"),
      textLeft: "",
      textRight: "",
      hasBackButton: true,
    });
  }, [language]);

  return (
    <Container className={generalStyles.EmbeddedContainer}>
      <Row className={styles.ErrorBox}>
        <div className={generalStyles.Box2}>
          <span className="b3">
            {t("ErrorContents1")}
            <a
              href="https://brightpensioen.nl/contact/"
              rel="noreferrer"
              target="_blank"
            >
              {t("ErrorContents2")}
            </a>
            {t("ErrorContents3")}
          </span>
        </div>
      </Row>
    </Container>
  );
};

export default ErrorPage;
