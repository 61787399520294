export const toEuroString = (
  amount?: number,
  positiveSign = false,
  digits = 2
) => {
  const removeZeroes = (input: string): string => {
    const splitted = input.split(",");
    return splitted[0];
  };

  if (amount) {
    // Fix for negative 0
    amount = +amount.toFixed(digits) + 0;
    let formatted = new Intl.NumberFormat("nl-NL", {
      signDisplay: positiveSign ? "exceptZero" : "auto",
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(amount)
      .replace(/^(\D+)/, "$1 ");

    if (formatted.endsWith("00")) formatted = removeZeroes(formatted);

    return formatted;
  }
  return "€ 0";
};

export const toEuroStringDocument = (
  amount?: number,
  positiveSign = false,
  digits = 2
) => {
  if (amount) {
    // Fix for negative 0
    amount = +amount.toFixed(digits) + 0;
    const formatted = new Intl.NumberFormat("nl-NL", {
      signDisplay: positiveSign ? "exceptZero" : "auto",
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })
      .format(amount)
      .replace(/^(\D+)/, "$1 ");

    return formatted;
  }
  return "€ 0,00";
};
