import { useQuery } from "react-query";
import client from "../../api/client";
import { AccountOverview } from "../../types/AccountOverview";

const getAccountOverview = async () => {
  const result = await client.get("Portal/PortalAccountOverview");
  return result.data.data;
};

export const useAccountOverviewQuery = () => {
  const { isLoading, error, data, refetch } = useQuery<AccountOverview, Error>(
    "accountOverview",
    getAccountOverview
  );

  return { isLoading, error, data, refetch };
};
