import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
  useState,
} from "react";
import { Props } from "../../shared/intrefaces";
import { IModalContext, DefaultModalData } from "./IModalContext";

export const ModalContext = createContext<IModalContext | undefined>(undefined);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      "useEmploymentContext must be used within an EmploymentContext"
    );
  }

  return context;
};

const defaultModalData: DefaultModalData = {
  show: false,
  type: "",
  data: null,
  title: "",
  refetchOnSuccessCallback: null,
};

export const ModalProvider: FunctionComponent<Props> = ({ children }) => {
  const [modalData, setModalData] =
    useState<DefaultModalData>(defaultModalData);

  const closeModal = () => {
    setModalData(defaultModalData);
  };

  const value = useMemo(
    () => ({
      modalData,
      setModalData,
      closeModal,
    }),
    [modalData, setModalData, closeModal]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
