import { useQuery } from "react-query";
import client from "../../api/client";
import { ProcessDates } from "../../types/ProcessDates";

const getProcessDates = async () => {
  const result = await client.get(`Portal/ProcessDates`);

  return result.data.data;
};

export const useProcessDatesQuery = () => {
  const { isLoading, error, data, refetch } = useQuery<ProcessDates, Error>(
    "processDates",
    getProcessDates
  );

  return { isLoading, error, data, refetch };
};
