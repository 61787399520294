import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

interface ErrorBoundaryTrackerProps {
  children: ReactNode;
  metadata?: any;
  FallbackComponent: any;
}

const ErrorBoundaryTracker = ({
  children,
  metadata,
  FallbackComponent,
}: ErrorBoundaryTrackerProps) => {
  const key = process.env.REACT_APP_INSTRUMENTATION_KEY;

  const connectToInsights = (): ApplicationInsights => {
    const reactPlugin = new ReactPlugin();
    const ai = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        extensions: [reactPlugin],
        disableFetchTracking: false,
      },
    });
    ai.loadAppInsights();
    return ai;
  };

  const logErrorToInsights = (error: Error) => {
    const ai = connectToInsights();
    ai.appInsights.trackException({
      error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { metadata },
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={() => FallbackComponent()}
      onError={logErrorToInsights}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryTracker;
