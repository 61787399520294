import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ParticipantStatusEnum } from "../../enums/ParticipantStatusEnum";
import ErrorPage from "../../features/ErrorPage/ErrorPage";
import {
  defaultParticipantSummary,
  ParticipantSummary,
} from "../../types/ParticipantSummary";
import { IParticipantSummaryContext } from "./IParticipantSummaryContext";
import { useParticipantSummaryQuery } from "./useParticipantSummaryQuery";

export const ParticipantSummaryContext = createContext<
  IParticipantSummaryContext | undefined
>(undefined);

export const useParticipantSummaryContext = (): IParticipantSummaryContext => {
  const context = useContext(ParticipantSummaryContext);

  if (!context) {
    throw new Error(
      "useParticipantSummaryContext should be used within an ParticipantSummaryContext"
    );
  }

  return context;
};

export const ParticipantSummaryProvider: FunctionComponent<{
  providesHeader?: boolean;
  children?: React.ReactNode;
}> = ({ providesHeader, children }) => {
  const { isLoading, error, data, refetch, isCreating, updateParticipant } =
    useParticipantSummaryQuery();

  const [summary, setSummary] = useState<ParticipantSummary>(
    data ?? defaultParticipantSummary
  );
  useEffect(() => {
    if (data) {
      setSummary(data);
    }
  }, [data]);

  const participantIsReadonly = (): boolean => {
    if (!summary) return true;
    return (
      summary.participantStatus === ParticipantStatusEnum.Paused ||
      summary.participantStatus === ParticipantStatusEnum.Blocked ||
      summary.participantStatus === ParticipantStatusEnum.Cancelled
    );
  };

  const value = useMemo(
    () => ({
      data,
      refetch,
      summary,
      setSummary,
      isCreating,
      updateParticipant,
      participantIsReadonly,
    }),
    [data, summary, isCreating]
  );

  if (isLoading) {
    return <Fragment />;
  }

  if (error) {
    return providesHeader ? <div /> : <ErrorPage />;
  }

  return (
    <ParticipantSummaryContext.Provider value={value}>
      {children}
    </ParticipantSummaryContext.Provider>
  );
};
