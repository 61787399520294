import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CallBright from "../../images/CallBright.svg";
import SocialEnterprise from "../../images/SocialEnterprise.svg";

import styles from "./Footer.module.scss";

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  const getCurrentYear = () => {
    return `${new Date(Date.now()).getFullYear()}`;
  };

  return (
    <footer className={styles.FooterWrapper}>
      <Container fluid className="p-0">
        <Row>
          <Container className="p-0">
            <Col className={styles.TopRow}>
              <img src={CallBright} className={styles.CallLogo} alt="" />
              <div className={styles.Content}>
                <div>
                  <p className={styles.FooterText}>
                    <span className={styles.Ready}>{t("WeAreReady")}</span>
                    <br />
                    {t("FindInFaq")}
                    <a
                      className={styles.FooterLink}
                      href="https://brightpensioen.nl/veelgestelde-vragen/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      FAQ
                    </a>
                    <br />
                    {t("Or")}
                    <a
                      className={styles.FooterLink}
                      href={"https://brightpensioen.nl/klantverzoek/"}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t("ContactUs")}
                    </a>
                    {t("UsDirectly")}
                  </p>
                </div>
              </div>
            </Col>
          </Container>
        </Row>
        <Row className={styles.EmptyRow}>&nbsp;</Row>
        <Row className={styles.BottomRow}>
          <ul className={styles.BottomInner}>
            <li>&copy; {getCurrentYear()} Copyright BrightPensioen</li>
            <li>
              <a
                className={styles.BottomLink}
                href="https://brightpensioen.nl/disclaimer/"
                target="_blank"
                rel="noreferrer"
              >
                {t("Disclaimer")}
              </a>
            </li>
            <li>
              <a
                className={styles.BottomLink}
                href="https://brightpensioen.nl/privacy-statement/"
                target="_blank"
                rel="noreferrer"
              >
                {t("PrivacyStatementCookies")}
              </a>
            </li>
          </ul>
        </Row>
        <div className={styles.SocialEnterprise}>
          <img src={SocialEnterprise} alt="" />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
