import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FutureContributionModal.module.scss";
import DatePicker from "react-datepicker";
import MoneyInput from "./../../../MoneyInput/MoneyInput";
import { useFutureContributionContext } from "../../../../contexts/FutureContributionContext/FutureContributionContext";
import { useModalContext } from "../../../../contexts/ModalContext/ModalContext";
import { IFutureContribution } from "./IFutureContribution";
import moment from "moment";
import { NotificationEnum } from "../../../../enums/NotificationEnum";
import { showNotification } from "./../../../../helpers/NotificationPortal";

interface IFutureContributionProps {
  data: any;
}

const FutureContribution: FunctionComponent<IFutureContributionProps> = ({
  data,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [state, setState] = useState<IFutureContribution>({
    id: null,
    startDate: null,
    newContribution: 0,
    employmentId: data.employmentId,
  });

  const {
    addFutureContribution,
    futureContribution,
    removeFutureContribution,
    updateFutureContribution,
  } = useFutureContributionContext();
  const { closeModal } = useModalContext();

  const minDate = new Date(
    moment().add(1, "M").startOf("month").format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (futureContribution) {
      const { id, startDate, newContribution, employmentId } =
        futureContribution;
      const date = startDate ? new Date(startDate) : startDate;
      setState({
        id,
        startDate: date,
        newContribution,
        employmentId,
      });
    }
  }, [futureContribution]);

  const validate = () => {
    if (state.newContribution > 20_000) {
      showNotification(
        NotificationEnum.Error,
        t("ContributionHigherThan", {
          value: language === "en" ? "20.000" : "20.000 zijn",
        })
      );
      return false;
    }
    if (!state.startDate) {
      showNotification(NotificationEnum.Error, t("DateRequired"));
      return false;
    }

    return true;
  };

  const handleAddFutureContribution = async () => {
    const isValidData = validate();
    const {
      id,
      startDate: startDateObj,
      newContribution,
      employmentId,
    } = state;

    const startDate = moment(startDateObj)
      .add(Number(startDateObj?.getTimezoneOffset()) * -1, "m")
      .toDate();

    if (isValidData) {
      if (id) {
        await updateFutureContribution.mutateAsync({
          ...futureContribution,
          startDate,
          newContribution,
          employmentId,
        });
      } else {
        await addFutureContribution.mutateAsync({
          startDate,
          newContribution,
          employmentId,
        });
        data.refetchOnSuccessCallback();
      }
      closeModal();
    }
  };

  const handleDeleteFutureContribution = async () => {
    if (state.id) {
      await removeFutureContribution.mutateAsync(state.id);
      data.refetchOnSuccessCallback();
      closeModal();
    }
  };

  return (
    <>
      <div className={styles.InputReadonlyDiv}>
        <input
          className={styles.InputReadonly}
          value={data.inputValue}
          readOnly
        />
      </div>
      <div className={styles.DateWrapper}>
        <label data-testid="money-input-label" className={styles.Label}>
          {t("FromMonth")}:
        </label>
        <div className={styles.InputBordered}>
          <DatePicker
            selected={state.startDate}
            onChange={(date: any) => setState({ ...state, startDate: date })}
            isClearable
            placeholderText={t("ChooseMonth")}
            dateFormat="yyyy-MM-dd"
            minDate={minDate}
            className={styles.DatePicker}
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </div>
      </div>
      <div className={styles.MoneyInput}>
        <label data-testid="money-input-label" className={styles.Label}>
          {t("PerMonth")}:
        </label>
        <MoneyInput
          value={state.newContribution}
          onValueChange={(value: number) =>
            setState({ ...state, newContribution: value })
          }
          bordered
        />
      </div>
      <div className="d-flex w-100">
        {state.id && (
          <button
            onClick={handleDeleteFutureContribution}
            disabled={false}
            className={styles.DeleteFutureContribution}
          >
            {t("Delete")}
          </button>
        )}
        <button
          onClick={handleAddFutureContribution}
          disabled={false}
          className={styles.AddFutureContribution}
        >
          {t("Save")}
        </button>
      </div>
    </>
  );
};

export default FutureContribution;
