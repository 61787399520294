import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import logo from "../images/bright-thing.svg";
import { ModalEnum } from "../enums/ModalEnum";
import FutureContributionModal from "../features/Employer/Employments/FutureContributionModal/FutureContributionModal";
import generalStyles from "../scss/General.module.scss";
import { useModalContext } from "../contexts/ModalContext/ModalContext";
import { FutureContributionProvider } from "./../contexts/FutureContributionContext/FutureContributionContext";

export const ModalPortal: FC = () => {
  const { t } = useTranslation();

  const {
    modalData: { show, type, data, title },
    closeModal,
  } = useModalContext();

  let componentToRender;

  switch (type) {
    case ModalEnum.FutureContribution:
      componentToRender = (
        <>
          <FutureContributionProvider
            employmentId={data.shouldFetch ? data.employmentId : null}
          >
            <FutureContributionModal data={data} />
          </FutureContributionProvider>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <Modal
      size="lg"
      centered
      animation={false}
      className={generalStyles.Modal}
      contentClassName="border-0 rounded-0"
      onHide={closeModal}
      show={show}
    >
      <Modal.Header
        closeButton
        className={`border-0 ${generalStyles.CollectionModalHeader}`}
      >
        <img className={generalStyles.logo} src={logo} alt="" />
        <p className={generalStyles.HeaderTitle}> {t(title)}</p>
      </Modal.Header>
      <Modal.Body className={generalStyles.ModalBody}>
        <div
          className="popup text-center"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {componentToRender}
        </div>
      </Modal.Body>
    </Modal>
  );
};
