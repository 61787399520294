export enum ColorEnum {
  Blue = "17aee5",
  Pink = "e73180",
  Yellow = "f0e928",
  Orange = "f16922",
  Green = "2da249",
  White = "ffffff",
  Black = "000000",
  LightPink = "f5d6e4",
  Grey = "bcbcbc",
  LightYellow = "ffd8c4",
}
