import React, { Fragment, FunctionComponent, useState } from "react";
import BrightLogo from "../Icons/BrightLogo";
import { Link } from "react-router-dom";
import ParticipantMenu from "../ParticipantMenu/ParticipantMenu";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Close from "../Icons/Close";
import User from "../Icons/User";
import styles from "./Header.module.scss";

const Header: FunctionComponent = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const getIcon = () => {
    const iconProps = { width: "36px", height: "36px" };
    if (menuOpen) {
      return <Close {...iconProps} />;
    } else {
      return <User {...iconProps} />;
    }
  };
  return (
    <Fragment>
      <nav className={styles.Navigation}>
        <div className={styles.BrightLogo}>
          <Link to="/">
            <BrightLogo />
          </Link>
        </div>
        <LanguageSelector />
        <div onClick={() => setMenuOpen(!menuOpen)} className={styles.Button}>
          {getIcon()}
        </div>
      </nav>
      <div className={styles.PseudoRelative}>
        <ParticipantMenu show={menuOpen} setShow={setMenuOpen} />
      </div>
    </Fragment>
  );
};

export default Header;
