import React, { useEffect } from "react";
import App from "./App";
import { AccountOverviewProvider } from "./contexts/AccountOverviewContext/AccountOverviewContext";
import { CreatePDFProvider } from "./contexts/CreatePDFContext/CreatePDFContext";
import { CreateXmlProvider } from "./contexts/CreateXmlContext/CreateXmlContext";
import { useConf } from "./features/configuration/ConfigurationProvider";
import { useCookieBot } from "./hooks/useCookieBot";
// import { useTawkTo } from "./hooks/useTawkTo";
import interceptors from "./interceptors";
import ComposeProviders from "./helpers/ComposeProviders";
import { PensionOverviewProvider } from "./contexts/PensionOverviewContext/PensionOverviewContext";
import { ProcessDatesProvider } from "./contexts/ProcessDatesContext/ProcessDatesContext";
import { ModalProvider } from "./contexts/ModalContext/ModalContext";

const AppWraper = () => {
  const auth = useConf();
  interceptors.setupInterceptors(auth.getAccessToken);

  const domainGroupId = "3b9eb3f2-4b67-401c-a692-9a262003e5a5";
  const language = localStorage.getItem("langData") ?? "nl";

  useEffect(() => useCookieBot(domainGroupId, language), []);

  // Temporary disable tawk chat
  // The widget is causing issues with the website.
  // https://community.tawk.to/t/issue-with-tawk-chat-functionality-affecting-website-usability/2066/8
  // useEffect(() => useTawkTo(), []);

  if (!auth.isLoggedIn) {
    return <div>Loading...</div>;
  }

  return (
    <ComposeProviders
      components={[
        AccountOverviewProvider,
        PensionOverviewProvider,
        CreatePDFProvider,
        CreateXmlProvider,
        ProcessDatesProvider,
        ModalProvider,
      ]}
    >
      <App auth={auth} />
    </ComposeProviders>
  );
};

export default AppWraper;
