import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const Telephone: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon
      width={props.width}
      height={props.height}
      bordered={props.bordered}
      borderColor={props.borderColor}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 77 77"
        width={props.width}
        height={props.height}
      >
        <circle fill="#ffffff" cx="38.5" cy="38.5" r="38.5" />
        <circle fill="#ffffff" cx="43.39" cy="26.34" r="8.79" />
        <path
          fill="#010102"
          d="M50.68,26.34a7.29,7.29,0,1,1-6-7.17,7.35,7.35,0,0,1,6,7.17c0,1.93,3,1.94,3,0a10.44,10.44,0,0,0-6.74-9.65,10.28,10.28,0,1,0,6.74,9.65C53.71,24.41,50.71,24.41,50.68,26.34Z"
        />
        <path
          fill="#ffffff"
          d="M58.46,59.44v-5A12.59,12.59,0,0,0,45.9,41.87h-5A12.59,12.59,0,0,0,28.33,54.42v5Z"
        />
        <path
          fill="#010102"
          d="M60,59.44c0-3.12.3-6.44-.72-9.44A14.24,14.24,0,0,0,49,40.73a22.35,22.35,0,0,0-5-.36c-1.44,0-2.9-.08-4.33.06a14.22,14.22,0,0,0-11.47,8c-1.64,3.52-1.37,7.26-1.37,11a1.52,1.52,0,0,0,1.5,1.5H58.46a1.5,1.5,0,0,0,0-3H28.33l1.5,1.5c0-2.5-.29-5.23.33-7.67a11.21,11.21,0,0,1,8.73-8.22,33.92,33.92,0,0,1,7.54-.17,11.18,11.18,0,0,1,9.68,6.8c1.21,2.88.85,6.21.85,9.26A1.5,1.5,0,0,0,60,59.44Z"
        />
        <path
          fill="#ffffff"
          d="M22.6,19.18c-3,2.51-4.29,6.7-4,10.63a26.84,26.84,0,0,0,3.81,11.07,23.31,23.31,0,0,0,5.17,6.72,10.49,10.49,0,0,0,7.87,2.59c1.89-.26,2.28-.7,4-1.59l1.39-.87a6.81,6.81,0,0,0-.9-3.88A21,21,0,0,0,37.5,40.6a6,6,0,0,0-1.39-1.32,1.93,1.93,0,0,0-1.83-.15l-.79,1.2a6,6,0,0,1-1.43,1.27,2,2,0,0,1-1.84.13,2.7,2.7,0,0,1-.78-.69,18.55,18.55,0,0,1-4.26-9.41,1.86,1.86,0,0,1,0-.94,1.91,1.91,0,0,1,.74-.75c.89-.61,1.79-1.22,2.69-1.81l.08.12c.58-2.36-.37-4.82-1.59-6.92-.72-1.23-1.71-2.52-3.12-2.68Z"
        />
        <path
          fill="#010102"
          d="M21.54,18.12c-4.35,3.76-5.21,10-3.89,15.31A33.83,33.83,0,0,0,21.31,42a23,23,0,0,0,5.81,7.14A11.9,11.9,0,0,0,35,51.73a13.13,13.13,0,0,0,6-2.4,2.4,2.4,0,0,0,1.25-2.5,9.48,9.48,0,0,0-1.71-4.69c-1.37-2-3.77-5.64-6.7-4.46-.88.35-1.16,1.2-1.72,1.87s-1,1.1-1.67.43A12.14,12.14,0,0,1,29,37.92a17.43,17.43,0,0,1-2-4.64c-.1-.4-.2-.81-.28-1.22-.1-.57-.16-.58.15-1a12.06,12.06,0,0,1,2.5-1.69l-2.05-.54.07.13a1.52,1.52,0,0,0,2.74-.36,11.66,11.66,0,0,0-2.44-9.14,5.33,5.33,0,0,0-3.31-2.31,4.49,4.49,0,0,0-2.22.53c-1.79.67-1,3.57.8,2.89.31-.11,1.06-.23,1.27-.47.24-.08.14-.08-.3,0a1.52,1.52,0,0,1,.57.28,3.94,3.94,0,0,1,.92,1c1.24,1.8,2.29,4.17,1.82,6.38L30,27.49,30,27.37a1.52,1.52,0,0,0-2.05-.54,27.75,27.75,0,0,0-3.29,2.26,3.16,3.16,0,0,0-.84,3.15,19.7,19.7,0,0,0,2.65,7.19c1.15,1.88,2.75,4.45,5.31,3.88,1.81-.4,2.89-2,3.85-3.43l-.9.69c1.1-.39,2.52,2,3.06,2.72a6.75,6.75,0,0,1,1.54,4.44l.74-1.3c-2.33,1.47-4.5,2.82-7.39,2.13-4-1-6.57-4.35-8.52-7.7-3.53-6-6.78-15.15-.45-20.62C25.13,19,23,16.86,21.54,18.12Z"
        />
      </svg>
    </ResizableIcon>
  );
};

export default Telephone;
