import React, { FunctionComponent } from "react";
import brightLogo from "../../images/bright-logo.svg";

import styles from "./BrightLogo.module.scss";

const BrightLogo: FunctionComponent = () => {
  return <img className={styles.BrightLogo} src={brightLogo} />;
};

export default BrightLogo;
