import React, { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const BankCard: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon
      width={props.width}
      height={props.height}
      bordered={props.bordered}
      borderColor={props.borderColor}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 77 77"
        width={props.width}
        height={props.height}
      >
        <circle fill="#ffffff" cx="38.5" cy="38.5" r="38.5" />
        <path
          fill="#010102"
          d="M18.91,48.84V27.43c0-1.49-.11-3,0-4.5.14-1.77,1.69-1.63,3-1.63H53.54c1,0,2-.08,3,0,2,.17,1.6,2.52,1.6,3.95V48.33a1.91,1.91,0,0,1-2.16,2.11H33.08c-4.1,0-8.25.2-12.35,0a1.68,1.68,0,0,1-1.82-1.6c-.1-1.92-3.1-1.93-3,0a4.7,4.7,0,0,0,4.43,4.59c.34,0,.69,0,1,0H54c1.49,0,3.2.2,4.57-.48,2.43-1.22,2.56-3.57,2.56-5.9V39c0-5.25.06-10.51,0-15.76a4.74,4.74,0,0,0-4.82-4.9c-1.56,0-3.11,0-4.67,0H32.84c-4.09,0-8.2-.15-12.29,0-3,.11-4.64,2.45-4.64,5.27V48.84A1.5,1.5,0,0,0,18.91,48.84Z"
        />
        <rect
          fill="#ffffff"
          x="17.41"
          y="25.06"
          width="42.17"
          height="32.14"
          rx="3.09"
        />
        <path
          fill="#010102"
          d="M18.91,54.11V32.69c0-1.48-.11-3,0-4.5.14-1.77,1.69-1.63,3-1.63H53.54c1,0,2-.08,3,0,2,.17,1.6,2.52,1.6,4V53.59a1.91,1.91,0,0,1-2.16,2.11H33.08c-4.1,0-8.25.2-12.35,0a1.67,1.67,0,0,1-1.82-1.59c-.1-1.93-3.1-1.94-3,0a4.69,4.69,0,0,0,4.43,4.58c.34,0,.69,0,1,0H54c1.49,0,3.2.2,4.57-.48,2.43-1.21,2.56-3.57,2.56-5.9v-8.1c0-5.25.06-10.51,0-15.76a4.75,4.75,0,0,0-4.82-4.9c-1.56,0-3.11,0-4.67,0H32.84c-4.09,0-8.2-.15-12.29,0-3,.11-4.64,2.45-4.64,5.27V54.11A1.5,1.5,0,0,0,18.91,54.11Z"
        />
        <path
          fill="#010102"
          d="M48.77,45.19V37.07l-1.5,1.5H59.59l-1.5-1.5v8.12l1.5-1.5H47.27a1.5,1.5,0,0,0,0,3H59.59a1.52,1.52,0,0,0,1.5-1.5V37.07a1.52,1.52,0,0,0-1.5-1.5H47.27a1.52,1.52,0,0,0-1.5,1.5v8.12A1.5,1.5,0,0,0,48.77,45.19Z"
        />
        <path
          fill="#010102"
          d="M53,41.13h0l-.06.4h0l-.15.36h0l-.24.3h0l-.31.24h0l-.36.15h0l-.4.05h0l-.39-.05h0l-.35-.15h0l-.3-.24h0l-.23-.3h0l-.15-.36h0l0-.4h0l0-.4h0l.15-.36h0l.23-.3h0l.3-.23h0l.35-.15h0l.39-.05h0l.4.05h0l.36.15h0l.31.23h0l.24.3h0l.15.36h0l.06.4h0a1.27,1.27,0,0,0-.13-.58,1.24,1.24,0,0,0-.31-.48,1.72,1.72,0,0,0-1.06-.44l-.4,0a1.47,1.47,0,0,0-.66.39l-.24.3a1.57,1.57,0,0,0-.2.76,1.23,1.23,0,0,0,0,.2l0,.2a1.35,1.35,0,0,0,.08.19,1.1,1.1,0,0,0,.31.49l.16.12.15.12.18.07.19.08.4.06.41-.06.18-.08a.71.71,0,0,0,.18-.07l.16-.12.15-.12a1.12,1.12,0,0,0,.32-.49,1.67,1.67,0,0,0,.07-.19l0-.2a1.2,1.2,0,0,0,0-.2,1.4,1.4,0,0,0-.06-.41c.06.19-.09-.25-.15-.36l-.12-.15-.12-.16a1.43,1.43,0,0,0-.31-.24,1.62,1.62,0,0,0-.77-.21,1.45,1.45,0,0,0-.41.06,1.52,1.52,0,0,0-.67.39h0l-.23.31h0a1.48,1.48,0,0,0-.2.77,1.06,1.06,0,0,0,.13.58,1.08,1.08,0,0,0,.31.48,1,1,0,0,0,.48.31,1.06,1.06,0,0,0,.58.13l.4-.05a1.49,1.49,0,0,0,.66-.39l.23-.3A1.48,1.48,0,0,0,53,41.13Z"
        />
      </svg>
    </ResizableIcon>
  );
};

export default BankCard;
