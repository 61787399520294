import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { IMenuItem } from "./IMenuItem";

import styles from "./MenuItem.module.scss";

const MenuItem: FunctionComponent<IMenuItem> = ({
  to,
  header,
  description,
  onClick,
  children,
}) => {
  return (
    <Link onClick={onClick} className={styles.MenuItem} to={to}>
      <div className={styles.MenuItemWrapper}>
        <div className={styles.MenuItemIcon}>
          <div className={styles.AlignRight}>{children}</div>
        </div>
        <div className={styles.MenuItemContent}>
          <h5 className={styles.MenuItemHeader}>{header}</h5>
          <p className={styles.MenuItemDescription}>{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default MenuItem;
