import { StyleSheet, View } from "@react-pdf/renderer";
import { ProductTypeEnum } from "../../../../enums/ProductTypeEnum";
import { PdfHeader } from "./PdfHeader";
import { PdfText } from "./PdfText";
import { PlaceholderBox } from "../PlaceholderBox";

const styles = StyleSheet.create({
  box: {
    border: "2pt solid #17aee5",
    width: "100%",
    paddingVertical: 12,
    paddingHorizontal: 18,
  },
  boxRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 6,
  },
} as const);

type PdfBoxProps = {
  placeholder: PlaceholderBox;
};
export const PdfBox = ({ placeholder }: PdfBoxProps) => {
  const shouldRenderDividendTaxWithheld =
    placeholder.dividendTaxWhithheldPositive !== "€ 0" &&
    placeholder.productType === ProductTypeEnum.Box3;

  return (
    <View style={styles.box}>
      <PdfHeader center style={{ marginBottom: 6 }}>
        Deze bedragen vul je in op je aangifte inkomstenbelasting van{" "}
        {placeholder.chosenYear}
      </PdfHeader>
      <View style={styles.boxRow}>
        <PdfText noMargin>{placeholder.textInBox}</PdfText>
        <PdfText noMargin>
          {placeholder?.productType === ProductTypeEnum.Box1
            ? placeholder.totalContribution
            : placeholder.valueAtEndOfPreviousYear}
        </PdfText>
      </View>
      {shouldRenderDividendTaxWithheld && (
        <View style={styles.boxRow}>
          <PdfText noMargin>Ingehouden Nederlandse dividendbelasting</PdfText>
          <PdfText noMargin>{placeholder.dividendTaxWhithheldPositive}</PdfText>
        </View>
      )}
    </View>
  );
};
