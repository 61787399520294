import { useEffect } from "react";
import TagManager from "react-gtm-module";

export const useGoogleTagManager = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID;

    if (gtmId && !gtmId.includes("DEV")) {
      const tagManagerArgs = {
        gtmId,
      };

      TagManager.initialize(tagManagerArgs);
      TagManager.dataLayer({
        dataLayer: {
          event: "pageView",
          virtualUrl: window.location.href,
        },
      });
    }
  }, [window.location.pathname]);
};
