import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ColorEnum } from "../../../enums/ColorEnum";
import Add from "../../Icons/Add";

import styles from "./OpenAccount.module.scss";

const OpenAccount: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <a
      href="https://brightpensioen.nl/extra-rekening-aanvragen/"
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.OpenAccount}>
        <div className={styles.OpenAccountIcon}>
          <Add
            width="60px"
            height="60px"
            bordered
            borderColor={ColorEnum.Black}
          />
        </div>
        <div className={styles.OpenAccountContent}>
          <h5 className={styles.OpenAccountHeader}>{t("OpenAccount")}</h5>
          <p className={styles.Content}>{t("OpenAccountText")}</p>
        </div>
      </div>
    </a>
  );
};

export default OpenAccount;
