import moment from "moment";
import { UserAgentApplication, AuthenticationParameters } from "msal";
import React, { useEffect, useMemo, useState } from "react";
import * as jwt from "jsonwebtoken";
import { useConfiguration, useFeatureToggle } from "./api/configuration";
import { QueryStatus } from "../../enums/QueryStatusEnum";
import { PROTECTED_ROUTE } from "../../shared/constant";
import { Authentication } from "../../types/Authentication";

const ConfContext = React.createContext<Authentication | null>(null);

const ConfProvider: any = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [userAgentApplication, setUserAgentApplication] =
    useState<UserAgentApplication>();
  let authenticationParameters: AuthenticationParameters;
  let signInAuthority: string;

  const { data, status } = useConfiguration();
  const { featureToggle } = useFeatureToggle();

  useEffect(() => {
    status === QueryStatus.Success && instantiateAuthentication();
  }, [status]);

  const clientId = data?.auth?.clientId;
  const authority = data?.auth?.authority;
  const isDevelopment = data?.isDevelopmentEnvironment;
  const maximumRequestedAmountWithoutReason =
    data?.maximumRequestedAmountWithoutReason;
  let stateParam: string;
  const scopes = ["openid"];
  const tokenValidationTimeOffset = 5;
  const msalIdConst = "msal.idtoken";
  const timeLimit = 30 * 60 * 1000; // 30 minutes

  useEffect(() => {
    // Check the time difference between the current time and last activity time every 30 minute
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - lastActivity;

      if (timeDifference >= timeLimit) {
        // If the time difference is greater than or equal to 30 minutes, log the user out
        signOut();
      }
    }, timeLimit);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [lastActivity]);

  function getUserAgentApplication() {
    return new UserAgentApplication({
      auth: {
        authority: authority,
        clientId: clientId ?? "",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
      },
      cache: {
        cacheLocation: "sessionStorage",
      },
    });
  }

  const instantiateAuthentication = (): void => {
    const userAgent = getUserAgentApplication();
    setUserAgentApplication(userAgent);
    signInAuthority = authority ?? "";
    userAgent?.handleRedirectCallback((error: any, response: any) => {
      stateParam = userAgent.getAccountState(response?.accountState ?? "");

      if (response?.account?.accountIdentifier) {
        if (PROTECTED_ROUTE.some((route) => `/${route}` === stateParam)) {
          window.location.href = window.location.origin + stateParam;
        }
        setIsLoggedIn(true);
      }
    });
    authenticationParameters = {
      scopes: scopes,
      state: window.location.pathname,
    };
    signIn(userAgent);
  };

  function signIn(userAgent: UserAgentApplication) {
    if (isAccessTokenNearExpiration() && userAgent?.authority) {
      userAgent.authority = signInAuthority;
      userAgent.loginRedirect(authenticationParameters);
    } else if (!isAccessTokenNearExpiration()) {
      setIsLoggedIn(true);
    }
  }

  const getAccessToken = async () => {
    const token = sessionStorage.getItem(msalIdConst);
    if (!token) {
      return "";
    }
    setLastActivity(Date.now());
    if (userAgentApplication && isAccessTokenExpired()) {
      signOut();
    }
    if (userAgentApplication && isAccessTokenNearExpiration()) {
      authenticationParameters = {
        scopes: scopes,
      };
      const response = await userAgentApplication.acquireTokenSilent(
        authenticationParameters
      );
      return response.idToken.rawIdToken;
    }
    return token;
  };

  const isAccessTokenNearExpiration = () => {
    return checkTokenExpiration(tokenValidationTimeOffset);
  };

  const isAccessTokenExpired = () => {
    return checkTokenExpiration(0);
  };

  const checkTokenExpiration = (timeOffset: number) => {
    const decodedToken = getDecodedToken();

    if (decodedToken) {
      const currentDate = moment().toDate();
      const expDate = moment(new Date(decodedToken.exp * 1000))
        .subtract(timeOffset, "minutes")
        .toDate();
      return currentDate > expDate;
    }
    return true;
  };

  const getDecodedToken: any = () => {
    const token = sessionStorage.getItem(msalIdConst);
    return token ? jwt.decode(token) : "";
  };
  function signOut() {
    sessionStorage.removeItem(msalIdConst);
    setUserAgentApplication(getUserAgentApplication());
    userAgentApplication && userAgentApplication.logout();
    setUserAgentApplication(undefined);
    setIsLoggedIn(false);
  }

  const values = useMemo(
    () => ({
      getDecodedToken,
      signOut,
      getAccessToken,
      isLoggedIn,
      setIsLoggedIn,
      instantiateAuthentication,
      isDevelopment,
      maximumRequestedAmountWithoutReason,
      featureToggle,
    }),
    [
      isLoggedIn,
      setIsLoggedIn,
      maximumRequestedAmountWithoutReason,
      featureToggle,
    ]
  );
  return <ConfContext.Provider value={values} {...props} />;
};

function useConf() {
  const context = React.useContext(ConfContext);
  if (!context) {
    throw new Error(`useAuth must be used within a ConfProvider`);
  }
  return context;
}

export { useConf, ConfProvider };
