import client from "./api/client";

const interceptor = {
  setupInterceptors: async (getToken: () => Promise<string>) => {
    client.interceptors.request.use(async (config) => {
      config.headers = {
        Authorization: "Bearer " + (await getToken()),
      };

      return config;
    });
  },
};

export default interceptor;
