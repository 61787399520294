import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";
import Background from "../../../../images/BackgroundFinancialOverview.png";

const styles = StyleSheet.create({
  financialYear: {
    textAlign: "center",
    paddingTop: 42,
    paddingBottom: 45,
    color: "#17aee5",
  },
  image: {
    width: "100%",
    position: "absolute",
    minWidth: "100%",
    height: 842,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    fontFamily: "MuseoSansRounded",
  },
  section: {
    padding: 50,
    flexGrow: 1,
  },
} as const);

type PdfPageProps = {
  chosenYear: string;
};

export const PdfPage = ({
  chosenYear,
  children,
}: PropsWithChildren<PdfPageProps>) => (
  <Page size="A4" style={styles.page}>
    <View>
      <Image style={styles.image} src={Background} />
      <View style={styles.section}>
        <Text style={styles.financialYear}>
          Financieel jaaroverzicht {chosenYear}
        </Text>
        {children}
      </View>
    </View>
  </Page>
);
