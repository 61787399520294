import { PdfLink } from "./PdfLink";
import { PdfText } from "./PdfText";

export const PdfClosing = () => (
  <>
    <PdfText style={{ paddingTop: 24 }}>
      Heb je nog vragen of opmerkingen over je jaaroverzicht? Neem dan even{" "}
      <PdfLink src="https://brightpensioen.nl/contact">contact</PdfLink> op. Je
      kunt ons op werkdagen bereiken tussen 9.00 en 17.30 uur per mail, chat,
      WhatsApp of telefoon.
    </PdfText>
    <PdfText>Met vriendelijke groet</PdfText>
    <PdfText>Het BrightPensioen team</PdfText>
  </>
);
