import { Link, StyleSheet } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";

const styles = StyleSheet.create({
  link: {
    color: "#17aee5",
  },
} as const);

type PdfLinkProps = {
  src: string;
};

export const PdfLink = ({ children, src }: PropsWithChildren<PdfLinkProps>) => (
  <Link style={styles.link} src={src}>
    {children}
  </Link>
);
