import { Document, Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { PlaceholderBox } from "./PlaceholderBox";
import { PdfBox } from "./components/PdfBox";
import { PdfClosing } from "./components/PdfClosing";
import { PdfExplanationDividend } from "./components/PdfExplanationDividend";
import { PdfHeader } from "./components/PdfHeader";
import { PdfLink } from "./components/PdfLink";
import { PdfPage } from "./components/PdfPage";
import { PdfTable } from "./components/PdfTable";
import { PdfText } from "./components/PdfText";

Font.register({
  family: "NexaSlabHeavy",
  src: "/NexaSlabHeavy.ttf",
  format: "truetype",
  fontWeight: 900,
});

Font.register({
  family: "MuseoSansRounded",
  src: "/MuseoSansRounded-300.ttf",
  format: "truetype",
});

type BoxPdfProps = {
  placeholder: PlaceholderBox;
};

const BoxPdf = ({ placeholder }: BoxPdfProps) => {
  const styles = StyleSheet.create({
    user: {
      paddingBottom: 12,
      fontSize: 12,
    },
    hr: {
      borderBottom: "1pt solid #17aee5",
      marginBottom: 10,
    },
    welcome: {
      paddingTop: 12,
      fontSize: 12,
    },
    date: {
      paddingBottom: 24,
      fontSize: 12,
    },
    type: {
      color: "#17aee5",
      fontSize: 12,
    },
    hello: {
      paddingBottom: 20,
      paddingTop: 12,
      fontSize: 12,
    },
    financial: {
      paddingBottom: 12,
      fontSize: 12,
    },
  } as const);

  return (
    <Document>
      <PdfPage chosenYear={placeholder.chosenYear}>
        <View style={styles.user}>
          <Text>{placeholder.fullName}</Text>
          <Text>{placeholder.address}</Text>
          <Text>{placeholder.cityPostCode}</Text>
        </View>
        <View style={styles.hr}></View>
        <View style={styles.welcome}>
          <Text style={styles.date}>Amsterdam, {placeholder.creationDate}</Text>
          <Text style={styles.type}>
            Bright rekeningnummer: {placeholder.accountNumber}
          </Text>
          <Text>
            Betreft: Financieel jaaroverzicht {placeholder.chosenYear}
          </Text>
          <Text>Type rekening: {placeholder.productName}</Text>
          <Text style={styles.hello}>Hallo {placeholder.firstName},</Text>
          <Text style={styles.financial}>
            Hierbij je financieel jaaroverzicht van {placeholder.chosenYear}. In
            dit overzicht lees je hoeveel je in {placeholder.chosenYear} hebt
            ingelegd en opgebouwd.
          </Text>
        </View>

        <PdfBox placeholder={placeholder} />

        <View>
          <PdfText style={{ marginTop: 24, marginBottom: 18 }}>
            Voor een uitgebreidere uitleg over wat je moet invullen bij de
            aangifte inkomstenbelasting kun je op{" "}
            <PdfLink src="https://brightpensioen.nl/financieel-jaaroverzicht-brightpensioen/">
              onze website
            </PdfLink>{" "}
            terecht.
          </PdfText>
        </View>
        <View>
          <PdfText>
            Zie de volgende bladzijde voor een volledig overzicht van jouw
            rekening.
          </PdfText>
        </View>
      </PdfPage>
      <PdfPage chosenYear={placeholder.chosenYear}>
        <PdfHeader bold>
          {placeholder.boxName} - Specificatie van het vermogen dat je hebt
          opgebouwd
        </PdfHeader>
        <PdfTable placeholder={placeholder} />
        <PdfExplanationDividend productType={placeholder.productType} />
        <PdfClosing />
      </PdfPage>
    </Document>
  );
};

export default BoxPdf;
