import React, {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { ColorEnum } from "../../enums/ColorEnum";
import { useClickOutside } from "../../hooks/useClickOutside";
import ParticipantSummary from "./ParticipantSummary/ParticipantSummary";
import OpenAccount from "./OpenAccount/OpenAccount";
import Telephone from "../Icons/Telephone";
import MenuItem from "./MenuItem/MenuItem";
import Divider from "./Divider/Divider";
import SectionHeader from "./SectionHeader/SectionHeader";
import styles from "./ParticipantMenu.module.scss";
import BankCard from "../Icons/BankCard";
import SignOut from "../Icons/SignOut";
import { useConf } from "../configuration/ConfigurationProvider";

interface IParticipantMenu {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const ParticipantMenu: FunctionComponent<IParticipantMenu> = ({
  show,
  setShow,
}) => {
  const auth = useConf();
  const { t } = useTranslation();
  const onClose = () => setShow(false);
  const menuRef = useClickOutside(onClose);

  return (
    <div ref={menuRef} className={styles[`Menu-${show}`]}>
      <Fragment>
        <ParticipantSummary />
        <Divider />
        <OpenAccount />
        <Divider />
        <SectionHeader
          header={t("Profile")}
          description={t("ProfileSettings")}
        />
        <MenuItem
          to="/persoonlijk"
          header={t("PersonalInfo")}
          description={t("PersonalInfoDescription")}
          onClick={() => setShow(false)}
        >
          <Telephone
            width="50px"
            height="50px"
            bordered
            borderColor={ColorEnum.Pink}
          />
        </MenuItem>
        <Divider isPink />
        <MenuItem
          to="/bankgegevens"
          header={t("BankAccountDetails")}
          description={t("BankAccountDetailsDescription")}
          onClick={() => setShow(false)}
        >
          <BankCard
            width="50px"
            height="50px"
            bordered
            borderColor={ColorEnum.Pink}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          to="#"
          onClick={() => auth.signOut()}
          header={t("SignOut")}
          description=""
        >
          <SignOut
            width="50px"
            height="50px"
            bordered
            borderColor={ColorEnum.Black}
          />
        </MenuItem>
      </Fragment>
    </div>
  );
};

export default ParticipantMenu;
